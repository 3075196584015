import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import CustomToast from "../../components/CustomToast";
import Loader from "../../utils/Loader";
import { getAllFieldService } from "../../services/fieldService";
import ErrorMessage from "../../components/ErrorMessage";
import { getLocalStorageItem } from "../../utils/LocalStorage";
import MUITable from "../../components/Table";

function AllFieldsPage() {
  const [field, setField] = useState([]);
  const [loading, setLoading] = useState(false);

  const token = getLocalStorageItem();
  const getAllField = async () => {
    setLoading(true);
    try {
      const { data } = await getAllFieldService(token);
      if (data.data) {
        let temp = data.data;
        temp.map((item) => {
          if (item.isAllowed) item.isAllowed = "Approved";
          else item.isAllowed = "Not Approved";
        });
        setField(temp);
      }
    } catch (error) {
      CustomToast(
        "error",
        error.response.data.message || error.response.message
      );
    }
    setLoading(false);
  };
  useEffect(() => {
    getAllField();
    return () => {
      setField([]);
    };
  }, []);

  const columns = [
    { label: "Field Name", value: "name" },
    { label: "Approved", value: "isAllowed" },
  ];

  return (
    <div className="w-full">
      {loading ? (
        <Loader />
      ) : (
        <div className="flex flex-col w-full">
          <NavLink
            to={"/field/addField"}
            className="flex flex-col justify-center items-center rounded-md bg-slate-500 w-[30%] max-w-[200px] p-2 self-end m-2 hover:bg-slate-400"
          >
            <p className="text-white text-md font-semibold">Add Field</p>
          </NavLink>
          {field?.length ? (
            <div className="px-10">
              <MUITable
                columns={columns}
                data={field}
                path="/field/editField"
              />
              {/* <CustomTable
                column={[
                  { id: "Field Id", name: "Field Name", isAllowed: "Approved" },
                ]}
                field={filteredField}
                count={filteredField.length}
                filter={filter}
                setFilter={setFilter}
                path="/field/editField"
              /> */}
            </div>
          ) : (
            <ErrorMessage error="No field found" />
          )}
        </div>
      )}
    </div>
  );
}

export default AllFieldsPage;
