import React, { useEffect, useState } from "react";
import {
  getCurrentJobService,
  getSkillService,
  getVolunteerService,
} from "../../../services/AdditionalService";
import CustomToast from "../../../components/CustomToast";
import { ErrorHandler } from "../../../utils/ErrorHandler";
import MUITable from "../../../components/Table";
import { getLocalStorageItem } from "../../../utils/LocalStorage";
import Loader from "../../../utils/Loader";

const Volunteer = () => {
  const [volunteer, setVolunteer] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = getLocalStorageItem();

  const getVolunteer = async () => {
    setLoading(true);
    try {
      const { data } = await getVolunteerService(token);
      if (data) {
        let temp = [...data];
        temp.map((items) => {
          items.firstName = items?.user?.firstName;
          items.lastName = items?.user?.lastName;
          items.userId = items.user_id;
        });
        setVolunteer(temp);
      }

      setLoading(false);
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
      setLoading(false);
    }
  };

  useEffect(() => {
    getVolunteer();
  }, []);
  const columns = [
    {
      label: "Name Of Institution",
      value: "nameOfInstitution",
    },
    { label: "Year of Service", value: "yearOfService" },
    { label: "Service Provided", value: "serviceProvided" },
    { label: "First Name", value: "firstName" },
    { label: "Last Name", value: "lastName" },
  ];

  return (
    <div className="px-10 w-full">
      {loading ? (
        <Loader />
      ) : (
        <MUITable
          path={"/userManagment/userInEachRole/userDetail"}
          type={"user"}
          columns={columns}
          data={volunteer}
        />
      )}
    </div>
  );
};

export default Volunteer;
