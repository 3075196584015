import axios from "axios";
import { URL } from "../config/config";

export const addEtambMessageService = (value, token) => {
  return axios.post(`${URL}etambContactUs`, value);
};
export const editEtambMessageService = (value, token) => {
  return axios.put(`${URL}etambContactUs`, value, {
    headers: {
      "x-auth-token": token,
    },
  });
};
export const getEtambMessageService = (token) => {
  return axios.get(`${URL}etambContactUs`, {
    headers: {
      "x-auth-token": token,
    },
  });
};
export const getOneEtambMessageService = (id, token) => {
  return axios.get(`${URL}etambContactUs/${id}`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const deleteEtambMessageService = (id, token) => {
  return axios.delete(`${URL}etambContactUs/${id}`, {
    headers: {
      "x-auth-token": token,
    },
  });
};
