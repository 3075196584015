import React, { useState } from "react";
import FormContainer from "../../../components/CustomForm/FormContainer";
import FormField from "../../../components/CustomForm/FormField";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { AddAdminService } from "../../../services/adminService";
import Loader from "../../../utils/Loader";
import CustomToast from "../../../components/CustomToast";
import { ErrorHandler } from "../../../utils/ErrorHandler";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required().label("First name"),
  lastName: Yup.string().required().label("Last Name"),
  email: Yup.string().email().required().label("Email"),
  phoneNumber: Yup.string().required().label("Phone Number"),
  password: Yup.string().required().label("Password"),
  reEnterPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Please confirm your password"),
});
const AddAdmin = () => {
  const admin = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    reEnterPassword: "",
  };
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    delete values.reEnterPassword;
    setLoading(true);

    try {
      const { data } = await AddAdminService(values);
      if (data) {
        CustomToast("success", "Admin Registered Successfully");
        navigate("/superAdmin");
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setLoading(false);
  };
  return (
    <div>
      {loading && <Loader />}
      <FormContainer
        buttonTitle={"Add Admin"}
        initialValues={admin}
        handleSubmit={handleSubmit}
        validationSchema={validationSchema}
        width={"w-[90%] sm:w-[80%] md:w-[60%] lg:[50%]"}
      >
        <FormField
          type={"text"}
          name={"firstName"}
          label={"First Name"}
          width={"w-full"}
        ></FormField>
        <FormField
          type={"text"}
          name={"lastName"}
          label={"Last Name"}
          width={"w-full"}
        ></FormField>
        <FormField
          type={"text"}
          name={"email"}
          label={"Email"}
          width={"w-full"}
        ></FormField>
        <FormField
          type={"text"}
          name={"phoneNumber"}
          label={"Phone Number"}
          width={"w-full"}
        ></FormField>
        <FormField
          type={"password"}
          name={"password"}
          label={"Password"}
          width={"w-full"}
        ></FormField>
        <FormField
          width="w-full"
          label="Re-Enter Password"
          name="reEnterPassword"
          type="password"
          placeholder="Re-Enter Password"
        />
      </FormContainer>
    </div>
  );
};

export default AddAdmin;
