import { ErrorMessage, useFormikContext } from "formik";
import React from "react";
import ProgressBar from "../ProgressBar";
import { Button } from "@mui/material";
import { CloudUploadOutlined } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const FormImage = ({ label, name, width, progress, progressVisible }) => {
  const { values, setFieldValue } = useFormikContext();
  return (
    <div className={`${width} flex flex-col justify-start`}>
      <p className="text-[#4D5959] text-left text-[14px]">{label}</p>

      <Button
        component="label"
        variant="outlined"
        startIcon={<CloudUploadOutlined />}
        sx={{ width: "100%", marginY: 1, paddingX: 1 }}
      >
        Upload file
        <VisuallyHiddenInput
          placeholder={label}
          name={name}
          type="file"
          value={values?.name}
          onChange={(e) => {
            setFieldValue(name, e.target.files[0]);
          }}
        />
      </Button>
      {progressVisible && <ProgressBar progress={progress}></ProgressBar>}
      <ErrorMessage
        className="text-[#e95f5f] text-left text-[14px]"
        name={name}
        component="p"
      />
    </div>
  );
};

export default FormImage;
