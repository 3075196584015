import axios from "axios";
import { URL } from "../config/config";

export const getPaymentRequestService = (token) => {
  return axios.get(`${URL}getPaymentRequestInformation`, {
    headers: {
      "x-auth-token": token,
    },
  });
};
export const addPaymentDetailService = (value, token) => {
  return axios.post(`${URL}addPaymentDetail`, value, {
    headers: {
      "x-auth-token": token,
    },
  });
};
export const getPaymentDetailService = (id, token) => {
  return axios.get(`${URL}withdrawalPayment?id=${id}`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

// export const getPaymentDetailService = (id, token) => {
//   return axios.get(`${URL}payment/${id}`, {
//     headers: {
//       "x-auth-token": token,
//     },
//   });
// };
