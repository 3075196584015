import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

import ErrorMessage from "../../components/ErrorMessage";
import Paginations from "../../components/pagination";
import Loader from "../../utils/Loader";
import { paginate } from "../../utils/paginate";
import { BASE_URL } from "../../config/config";
import {
  deleteEventService,
  getAllEventService,
} from "../../services/eventService";
import { getLocalStorageItem } from "../../utils/LocalStorage";
import { Button, Popover } from "@mui/material";
import CustomToast from "../../components/CustomToast";

const WatchAllEvent = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const token = getLocalStorageItem();
  const [anchorEl, setAnchorEl] = useState(null);
  const [id, setId] = useState(null);
  const navigate = useNavigate();

  const handleClick = (event, id) => {
    setId(id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getAllEvent();
  }, []);

  const getAllEvent = async () => {
    setLoading(true);
    try {
      const { data } = await getAllEventService(token);
      if (data.data) setEvents(data.data);
    } catch (error) {
      console.log(error.response.data.message || error.message);
    }
    setLoading(false);
  };
  const handleDelete = async () => {
    setLoading(true);
    try {
      const { data } = await deleteEventService(id, token);
      if (data?.message) {
        getAllEvent();
        CustomToast("success", data.message);
        navigate("/event");
        handleClose();
      }
    } catch (error) {
      handleClose();
      CustomToast(
        "error",
        error?.response?.data ? error?.response?.data.message : error?.message
      );
    }
    setLoading(false);
  };

  const paginatedEvent = paginate(events, currentPage, pageSize);
  return (
    <div className="px-20">
      {loading ? (
        <Loader />
      ) : paginatedEvent?.length ? (
        <div className="flex flex-col">
          {paginatedEvent.map((events, index) => (
            <div key={index} className="flex flex-col">
              <Link
                state={{ eventId: events.id }}
                to={`/event/editEvent/${events.id}`}
                className="bg-slate-200 rounded-md p-5 my-5 flex flex-col md:flex-row"
              >
                {events?.picture && (
                  <img
                    src={`${BASE_URL}/${events?.picture}`}
                    className={"w-[250px] h-[200px]"}
                  ></img>
                )}
                <div className="flex flex-col md:ml-10">
                  <p className="text-left text-[20px] font-semibold">
                    {events.title}
                  </p>
                  <p className="text-left">{events.description}</p>
                  <p className="text-left">
                    {moment(events.date).format("DD-MMM-YYYY")}
                  </p>
                </div>
              </Link>
              <button
                type="submit"
                onClick={(e) => handleClick(e, events.id)}
                className="rounded-md h-[40px] cursor-pointer w-[100px] bg-red-800 text-white"
              >
                Delete
              </button>
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <div className="px-5 p-2.5">
                  <p>Are you sure you want to delete this event?</p>
                  <div className="flex justify-evenly my-2">
                    <Button
                      color="success"
                      size="small"
                      variant="contained"
                      onClick={handleDelete}
                    >
                      Yes
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      color="error"
                      onClick={handleClose}
                    >
                      No
                    </Button>
                  </div>
                </div>
              </Popover>
            </div>
          ))}
          <Paginations
            itemsCount={events?.length}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          ></Paginations>
        </div>
      ) : (
        <ErrorMessage error={"No events found"} />
      )}
    </div>
  );
};

export default WatchAllEvent;
