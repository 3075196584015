import React from "react";
import { BASE_URL } from "../config/config";

function CustomImageWithLabel({
  label,
  picture,
  containerWidth,
  height,
  width,
}) {
  return (
    <div className={`m-2 ${containerWidth}`}>
      <p className="text-left text-[18px] mb-5 text-slate-500">{label}</p>
      {picture && (
        <a href={`${BASE_URL + picture}`} target="_blank">
          <img
            alt="Image"
            style={{ height, width }}
            src={`${BASE_URL + picture}`}
            className="max-w-full max-h-full object-contain"
          />
        </a>
      )}
    </div>
  );
}

export default CustomImageWithLabel;
