import axios from "axios";
import { URL } from "../config/config";
import { getToken } from "../utils/getToken";

export const getAllRoleService = (token) => {
  return axios.get(`${URL}roles`, getToken());
};
export const addRoleService = (value, token) => {
  return axios.post(`${URL}role`, value,  getToken());
};
export const updateRoleService = (id, role, token) => {
  return axios.put(`${URL}role/${id}`, role,  getToken());
};

export const getRoleInformationService = (id, token) => {
  return axios.get(`${URL}role/${id}`,  getToken());
};
