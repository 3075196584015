import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomToast from "../../components/CustomToast";
import ErrorMessage from "../../components/ErrorMessage";

import ListInformation from "../../components/ListInformation";
import Loader from "../../utils/Loader";
import { getLocalStorageItem } from "../../utils/LocalStorage";
import { BASE_URL, URL } from "../../config/config";
import {
  approveUser,
  clearFormService,
  clearShareFormService,
  getUserInformationByAdminService,
  updateUserScoreValue,
} from "../../services/adminService";
import CustomImageWithLabel from "../../components/CustomImageWithLabel";
import { Button, Popover } from "@mui/material";
import { ErrorHandler } from "../../utils/ErrorHandler";
import CustomModalComponent from "../../common/CustomModal/CustomModal";
import EditUser from "../AddUsers/UserAddEdit/EditUser";

function UserDetail() {
  const { state } = useLocation();
  const [user, setUser] = useState({});
  const [scoreValue, setScoreValue] = useState();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const token = getLocalStorageItem();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getUser = async () => {
    setLoading(true);
    try {
      const { data } = await getUserInformationByAdminService(state?.id, token);
      if (data.data) {
        setUser(data.data);
        setScoreValue(data.data.scoreValue);
      }
    } catch (error) {
      CustomToast("failed", error?.response?.data.message || error?.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    getUser();
    return () => {};
  }, []);

  const handleApprove = async () => {
    setLoading(true);
    try {
      const { data } = await approveUser(
        { id: state.userId, role: state.role },
        token
      );
      if (data) {
        CustomToast("success", "User approved");
        navigate("/approveUser");
        handleClose();
      }
    } catch (error) {
      handleClose();
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setLoading(false);
  };

  const handleScoreValue = async () => {
    setLoading(true);
    try {
      const { data } = await updateUserScoreValue(state.id, scoreValue, token);
      if (data.data) {
        getUser();
        CustomToast("success", data.data);
        handleClose();
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
      setLoading(false);
      handleClose();
    }
  };

  const handleClearForm = async () => {
    setLoading(true);
    try {
      const { data } = await clearFormService(
        { userId: user.userId, paymentID: null },
        token
      );
      if (data.data) {
        CustomToast("success", data.data);
        navigate("/approveUser");
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setLoading(false);
  };

  return (
    <div>
      <div className="flex flex-col px-8">
        {loading ? (
          <Loader />
        ) : user?.firstName ? (
          <>
            <Link
              to={"/userManagment/userInEachRole/userDetail/editUser"}
              state={{ id: user?.userId }}
              className="max-w-[150px] self-end"
            >
              <p className="bg-violet-700 w-max text-white text-[14px] md:text-[16px] p-2 rounded-md">
                Edit User
              </p>
            </Link>

            {/* <Button
              sx={{ width: "max-content" }}
              onClick={() => setOpen(true)}
              color="secondary"
              type="button"
              variant="outlined"
            >
              Edit User
            </Button> 
            <CustomModalComponent
              open={open}
              handleClose={() => setOpen(false)}
            >
              <EditUser
                passedSelectedRole={user?.role_id}
                user={user}
              />
            </CustomModalComponent> */}
            <CustomImageWithLabel
              picture={user.profilePicture}
              height={300}
              width={"w-full md:w-[45%]"}
              imageWidth={"w-full "}
            />
            <div className="flex flex-col md:flex-row flex-wrap md:justify-between">
              {!!user.firstName && (
                <ListInformation
                  disabled={true}
                  label="Name"
                  value={user?.firstName}
                />
              )}
              {!!user?.lastName && (
                <ListInformation
                  disabled={true}
                  label="Father Name"
                  value={user?.lastName}
                />
              )}
              {!!user?.grandFatherName && (
                <ListInformation
                  disabled={true}
                  label="Grand Father Name"
                  value={user?.grandFatherName}
                />
              )}
              {!!user.email && (
                <ListInformation
                  disabled={true}
                  label="Email"
                  value={user?.email}
                />
              )}
              {!!user?.country && (
                <ListInformation
                  disabled={true}
                  label="Country"
                  value={user?.country}
                />
              )}
              {!!user?.state && (
                <ListInformation
                  disabled={true}
                  label="State"
                  value={user?.state}
                />
              )}
              {!!user.phoneNumber && (
                <ListInformation
                  disabled={true}
                  label="Phone Number"
                  value={user?.phoneNumber}
                />
              )}
              {!!user?.city && (
                <ListInformation
                  disabled={true}
                  label="City"
                  value={user?.city}
                />
              )}
              {!!user?.fields?.length ? (
                <div className="w-[100%] md:w-[45%] md:m-2">
                  <p className="text-left text-[14px] md:text-[16px] text-slate-500">
                    Field
                  </p>

                  {user?.fields?.map((fields, index) => (
                    <p
                      key={index}
                      className="text-left bg-slate-200 rounded-md my-0.5 p-1.5 text-[14px] md:text-[16px] justify-center item-center"
                    >
                      {fields?.name ?? ""}
                    </p>
                  ))}
                </div>
              ) : null}
              {!!user?.university && (
                <ListInformation
                  disabled={true}
                  label="University"
                  value={user?.university}
                />
              )}

              {!!user?.yearGraduated && (
                <ListInformation
                  disabled={true}
                  label="Year graduated"
                  value={moment(user?.yearGraduated).format("DD-MMM-YYYY")}
                />
              )}
              {!!user?.birthDate && (
                <ListInformation
                  disabled={true}
                  label="Birth Date"
                  value={moment(user?.birthDate).format("DD-MMM-YYYY")}
                />
              )}
              {!!user?.motherFullName && (
                <ListInformation
                  disabled={true}
                  label="Mother Full Name"
                  value={user?.motherFullName}
                />
              )}
              {!!user?.referalCode && (
                <ListInformation
                  disabled={true}
                  label="Referal Code"
                  value={user?.referalCode}
                />
              )}

              {!!user?.invitedBy == "others" && (
                <ListInformation
                  disabled={true}
                  label="Invited By"
                  value={"Promotion"}
                />
              )}
              {!!user?.tinNumber && (
                <ListInformation
                  disabled={true}
                  label="Tin Number"
                  value={user?.tinNumber}
                />
              )}
              {!!user?.withdrawalPercentage && (
                <ListInformation
                  disabled={true}
                  label="Withdrawal Percentage"
                  value={`${user?.withdrawalPercentage * 100}%`}
                />
              )}

              {!!user?.registrationDate && (
                <ListInformation
                  disabled={true}
                  label="Registration date"
                  value={moment(user?.registrationDate).format("DD-MMM-YYYY")}
                />
              )}
              {!!user?.scoreValue && (
                <ListInformation
                  disabled={true}
                  label="Score Value"
                  value={user?.scoreValue}
                />
              )}
              {!!user?.role?.name && (
                <ListInformation
                  disabled={true}
                  label="Current Role"
                  value={user?.role?.name}
                />
              )}
              <div className="w-full flex flex-col md:flex-row justify-between">
              
                <div className="w-full md:w-[45%]">
                  {!!user?.idPicture && (
                    <CustomImageWithLabel
                      picture={user.idPicture}
                      label={"Id /Passport"}
                      height={300}
                      width={"w-full md:w-[45%]"}
                      imageWidth={"w-full "}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="my-5 bg-slate-200 rounded-lg px-10 py-5 w-[100%] self-center">
              {state?.type?.toLowerCase() == "user" ? (
                <div className="flex flex-col items-center">
                  <div className="flex items-center my-5 w-[100%]">
                    <input
                      type="number"
                      onChange={(e) => setScoreValue(e.currentTarget.value)}
                      value={scoreValue}
                      className="bg-slate-50 text-black rounded-md p-1 w-[100%] focus:outline-slate-400"
                    ></input>
                    <button
                      onClick={handleClick}
                      className="bg-green-500 w-[100%] rounded-md cursor-pointer text-white text-[14px] md:text-[16px] p-1 mx-[10px]"
                    >
                      Update Score Value
                    </button>
                    <Popover
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "top",
                      }}
                    >
                      <div className="px-5 p-2.5">
                        <p>Are you sure you want to update score value?</p>
                        <div className="flex justify-evenly my-2">
                          <Button
                            color="success"
                            size="small"
                            variant="contained"
                            onClick={handleScoreValue}
                          >
                            Yes
                          </Button>
                          <Button
                            size="small"
                            variant="contained"
                            color="error"
                            onClick={handleClose}
                          >
                            No
                          </Button>
                        </div>
                      </div>
                    </Popover>
                  </div>
                  <div className="w-full">
                    <Link
                      to={
                        "/userManagment/userInEachRole/userDetail/paymentDetail"
                      }
                      state={{ id: user?.userId }}
                      className="w-[100%] lg:w-[40%] "
                    >
                      <p className="bg-slate-800 text-white text-[14px] md:text-[16px] p-2 rounded-md">
                        View Payment Detail
                      </p>
                    </Link>
                  </div>
                </div>
              ) : (
                <div className="flex my-2 justify-between w-[100%] md:w-[50%] max-w-[300px]">
                  <button
                    onClick={handleApprove}
                    className="bg-green-500 w-max min-w-[80px] rounded-md cursor-pointer text-[14px] md:text-[16px] text-white p-0.5 md:p-1"
                  >
                    Approve
                  </button>
                  <button
                    onClick={handleClick}
                    className="bg-red-500 w-max min-w-[80px] rounded-md cursor-pointer text-[14px] md:text-[16px] text-white p-0.5 md:p-1"
                  >
                    Clear
                  </button>
                  <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl && anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <div className="px-5 p-2.5">
                      <p>Are you sure you want to clear the user info?</p>
                      <div className="flex justify-evenly my-2">
                        <Button
                          color="success"
                          size="small"
                          variant="contained"
                          onClick={handleClearForm}
                        >
                          Yes
                        </Button>
                        <Button
                          size="small"
                          variant="contained"
                          color="error"
                          onClick={handleClose}
                        >
                          No
                        </Button>
                      </div>
                    </div>
                  </Popover>
                </div>
              )}
            </div>
          </>
        ) : (
          <ErrorMessage error={"Error Occurred"} />
        )}
      </div>
    </div>
  );
}

export default UserDetail;
