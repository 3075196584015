import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import Loader from "../../utils/Loader";
import FormContainer from "../../components/CustomForm/FormContainer";
import FormField from "../../components/CustomForm/FormField";
import ErrorMessage from "../../components/ErrorMessage";
import CustomToast from "../../components/CustomToast";
import {
  getFieldDetailService,
  updateFieldService,
} from "../../services/fieldService";
import { getLocalStorageItem } from "../../utils/LocalStorage";

const EditField = () => {
  const { state } = useLocation();
  const [field, setField] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const token = getLocalStorageItem();
  const validationSchema = Yup.object().shape({
    name: Yup.string().required().max(250).label("Field name"),
  });

  const handleSubmit = async (values) => {
    setLoading(true);
    values.isAllowed = true;
    try {
      const { data } = await updateFieldService(state?.id, values, token);
      if (data.data) {
        navigate("/field");
        CustomToast("success", data.message);
      }
    } catch (error) {
      console.log(
        error?.response?.data ? error?.response?.data?.message : error?.message
      );
      CustomToast(
        "error",
        error?.response?.data ? error?.response?.data?.message : error?.message
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    getFieldDetail();
  }, []);

  const getFieldDetail = async () => {
    setLoading(true);
    try {
      const { data } = await getFieldDetailService(state.id, token);
      if (data?.data) setField(data.data);
    } catch (error) {
      CustomToast(
        "error",
        error?.response?.data ? error?.response?.data.message : error?.message
      );
    }
    setLoading(false);
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : field?.name ? (
        <FormContainer
          width={"w-[100%] sm:w-[80%] md:w-[60%] lg:[50%]"}
          buttonTitle={"Edit field and approve "}
          initialValues={field}
          handleSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          <FormField
            width={"w-[90%]"}
            type={"text"}
            name={"name"}
            label={"Field"}
          ></FormField>
        </FormContainer>
      ) : (
        <ErrorMessage error={"Error occured, Please try again"}></ErrorMessage>
      )}
    </div>
  );
};

export default EditField;
