import React, { useEffect, useState } from "react";

import { NavLink } from "react-router-dom";

import Loader from "../../utils/Loader";
import ErrorMessage from "../../components/ErrorMessage";
import { getAllBankService } from "../../services/bankService";
import MUITable from "../../components/Table";

const BankPage = () => {
  const [bank, setBank] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllBank();
  }, []);

  const getAllBank = async () => {
    setLoading(true);
    try {
      const { data } = await getAllBankService();
      setBank(data.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const columns = [
    { label: "Bank Name", value: "name" },
    { label: "Account Number", value: "accountNumber" },
  ];

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="flex flex-col w-full">
          <NavLink
            to={"/bank/addBank"}
            className="flex flex-col justify-center items-center rounded-md bg-slate-500 w-[30%] max-w-[200px] p-2 self-end m-2 hover:bg-slate-400"
          >
            <p className="text-white text-md font-semibold">Add Bank Account</p>
          </NavLink>
          {bank.length ? (
            <div className="flex flex-col px-10">
              <MUITable columns={columns} data={bank} path={`/bank/editBank`} />
              {/* <CustomTable
                column={[
                  {
                    name: "Bank Name",
                    accountNumber: "Account Number",
                  },
                ]}
                count={filteredBank.length}
                field={filteredBank}
                path={`/bank/editBank`}
                filter={filter}
                setFilter={setFilter}
              /> */}
            </div>
          ) : (
            <ErrorMessage error={"No Bank account found"} />
          )}
        </div>
      )}
    </div>
  );
};

export default BankPage;
