import axios from "axios";
import { URL } from "../config/config";
import { getToken } from "../utils/getToken";

export const getApprovedFieldService = (token) => {
  return axios.get(`${URL}fields`, getToken());
};
export const getAllFieldService = (token) => {
  return axios.get(`${URL}field`,  getToken());
};
export const addFieldService = (value, token) => {
  return axios.post(`${URL}field`, value,  getToken());
};
export const updateFieldService = (id, values, token) => {
  return axios.put(`${URL}field/${id}`, values, getToken());
};
export const getFieldDetailService = (id, token) => {
  return axios.get(`${URL}field/${id}`, getToken());
};
