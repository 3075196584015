import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Paginations from "../components/pagination";
import { paginate } from "./paginate";

function CustomTable({ field, column, filter, setFilter, count, path }) {
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const fields = paginate(field, currentPage, pageSize);

  return (
    <div className="flex flex-col justify-center m-auto w-[100%] p-5">
      <div className="flex flex-row rounded-md bg-slate-500 my-3 place-self-start">
        <label
          className="text-lg text-white font-semibold p-1"
          htmlFor="filter"
        >
          Filter
        </label>
        <input
          type="text"
          name="filter"
          className="border border-slate-400 m-2 focus:outline-none rounded-sm focus:border-red-500 focus:ring-1 focus:ring-red-500"
          value={filter}
          onChange={(e) => setFilter(e.currentTarget.value)}
        ></input>
      </div>
      {count === 0 ? (
        <p className="text-red-400 text-[30px]">No data </p>
      ) : (
        <>
          <table className="table-auto justify-center border-slate-500 w-[100%]">
            <thead className="bg-slate-800">
              <tr>
                {column?.map((columns, index) =>
                  Object.keys(columns).map((key, index) => (
                    <th
                      key={index}
                      className="border text-white p-2 border-slate-600"
                    >
                      {columns[key]}
                    </th>
                  ))
                )}
              </tr>
            </thead>
            <tbody>
              {fields?.map((field, index) => (
                <tr
                  className="cursor-pointer bg-slate-500 hover:bg-slate-400"
                  key={index}
                  onClick={() => {
                    navigate(path, {
                      state: {
                        id: field[Object.keys(field)[0]],
                      },
                    });
                  }}
                >
                  {Object.keys(field).map((key, index) =>
                    column?.map((columns) =>
                      Object.keys(columns)
                        .filter((keys) => keys == key)
                        .map((c) => (
                          <td
                            key={index}
                            className="border border-slate-800 text-[whitesmoke] p-2 text-lg text-left "
                          >
                            {field[c] === true && c == "isAllowed"
                              ? "Approved"
                              : field[c] === true && c == "isContacted"
                              ? "Resolved"
                              : field[c] === false && c == "isAllowed"
                              ? "Not approved"
                              : field[c] === false && c == "isContacted"
                              ? "Not resolved"
                              : field[c]}
                          </td>
                        ))
                    )
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          <Paginations
            itemsCount={count}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          ></Paginations>
        </>
      )}
    </div>
  );
}

export default CustomTable;
