import axios from "axios";
import { URL } from "../config/config";

export const addConstantService = async (value, token) => {
  return await axios.post(`${URL}constant`, value, {
    headers: {
      "x-auth-token": token,
    },
  });
};
export const getConstantService = async (token) => {
  return await axios.get(`${URL}constant`, {
    headers: {
      "x-auth-token": token,
    },
  });
};
export const getSpecificConstantService = async (id, token) => {
  return await axios.get(`${URL}constant/${id}`, {
    headers: {
      "x-auth-token": token,
    },
  });
};
export const editSpecificConstantService = async (value, token) => {
  return await axios.put(`${URL}constant`, value, {
    headers: {
      "x-auth-token": token,
    },
  });
};
