import React from "react";
import { ErrorMessage, useFormikContext } from "formik";
import { CountryDropdown } from "react-country-region-selector";

const FormCountrySelector = ({ name ,value}) => {
  const { setFieldValue } = useFormikContext();
  return (
    <div  className={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}>
      <label
        htmlFor="field"
        className="block text-[#4D5959] text-[14px] md:text-[16px] p-[2px] "
      >
        Country
      </label>
      <CountryDropdown
        classes="py-2.5 rounded-md bg-[#EFF0F2] text-[14px] w-full outline-[#99d5e9]"
        value={value}
        onChange={(val) => setFieldValue(name, val)}
      />
      <ErrorMessage
        className="text-[#e95f5f] text-left text-[14px]"
        name={name}
        component="p"
      />
    </div>
  );
};

export default FormCountrySelector;
