import React from 'react'
import FormDropDown from '../../../components/CustomForm/FormDropDown';
import FormField from '../../../components/CustomForm/FormField';
import FormDatePicker from '../../../components/CustomForm/FormDatePicker';
import { useState } from 'react';
import { getAllBankService } from '../../../services/bankService';
import { getRoleInformationService } from '../../../services/roleService';
import { getShareForRoleService } from '../../../services/shareService';
import { ErrorHandler } from '../../../utils/ErrorHandler';
import CustomToast from '../../../components/CustomToast';
import { getLocalStorageItem } from '../../../utils/LocalStorage';
import { useEffect } from 'react';
import styled from '@emotion/styled';
import { Chip, Divider } from '@mui/material';
import Loader from '../../../utils/Loader';

const Root = styled("div")(({ theme }) => ({
    width: "100%",
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    "& > :not(style) ~ :not(style)": {
      marginTop: theme.spacing(2),
    },
  }));

const Share = ({roleId}) => {
  const [loading, setLoading] = useState(false);
  const [percentage, setPercentage] = useState(0.5);

  const [bank, setBank] = useState([]);
  const [roles, setRoles] = useState({});
  const [share, setShare] = useState({});
  const token = getLocalStorageItem();


  const fetchAllInformation = async () => {
    setLoading(true);
    try {
      const { data: bankResponse } = await getAllBankService(token);
      const { data: roleResponse } = await getRoleInformationService(
        roleId,
        token
      );
      const { data: shareRoleResponse } = await getShareForRoleService(
        roleId,
        token
      );
         shareRoleResponse.data.role == "G5"
        ? setPercentage(0.5)
        : shareRoleResponse.data.role == "G6"
        ? setPercentage(0.3)
        : setPercentage(0.2);

      setShare(shareRoleResponse.data);
      setBank(bankResponse.data);
      setRoles(roleResponse.data);
      setLoading(false);
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("Err", e.message));
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllInformation();
  }, [roleId]);

 
  let amount;
  if (share.amount)
    amount = [
      {
        id: share?.amount,
        percentage: "100%",
        name: `${share?.amount} ETB`,
      },
      {
        id: 0.75 * share?.amount,
        percentage: "75%",
        name: `${0.75 * share?.amount} ETB`,
      },
      {
        id: 0.5 * share?.amount,
        percentage: "50%",
        name: `${0.5 * share?.amount} ETB`,
      },
      roles?.name == "G7"
        ? {
            id: percentage * share?.amount,
            percentage: percentage * 100 + "%",
            name: `${percentage * share?.amount} ETB`,
          }
        : roles?.name == "G6"
        ? {
            id: percentage * share?.amount,
            percentage: percentage * 100 + "%",
            name: `${percentage * share?.amount} ETB`,
          }
        : {},
    ];

  let array;
  array = amount?.filter((items) => {
    items.name = items.name
      ? `Paid ${items?.id} for ${items?.percentage}`
      : "";
    return items;
  });

  return (
    <>
      <Root>
        <Divider sx={{ width: "100%", my: 2 }}>
          <Chip
            label={`G${roleId} Information`}
            size="small"
            sx={{ backgroundColor: "orange", color: "white" }}
          />
        </Divider>
      </Root>
      {loading ? (
        <div className='flex w-full justify-center'>
          <Loader width={40} />
        </div>
      ) : (
        <>
          <FormDropDown
            data={array}
            label={"Share"}
            name={"paymentAmount"}
            width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
          />
          <FormDropDown
            data={bank}
            label={"Bank"}
            name={"bank"}
            width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
          />

          <FormField
            label="Payment Reference Number"
            name="paymentReferenceNo"
            type="text"
            width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
          />

          <FormDatePicker
            label={"Payment Date"}
            name={"paymentDate"}
            width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
          />
        </>
      )}
    </>
  );
}

export default Share