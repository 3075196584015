import React, { useState } from "react";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import FormField from "../../components/CustomForm/FormField";
import FormContainer from "../../components/CustomForm/FormContainer";
import { resetPasswordService } from "../../services/adminService";
import { ErrorHandler } from "../../utils/ErrorHandler";
import FormButton from "../../components/CustomForm/FormButton";
import CustomToast from "../../components/CustomToast";

const ResetPassword = () => {
  const validationSchema = Yup.object().shape({
    password: Yup.string().required().label("Password"),
    reEnterPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Please confirm your password"),
  });
  const [loading, setLoading] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (value) => {
    setLoading(true);
    try {
      const { data } = await resetPasswordService(value, token);
      if (data.message) {
        CustomToast("success", data.message);
        navigate("https://admin.keyug.com");
        // window.location = "https://admin.keyug.com";
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setLoading(false);
  };

  return (
    <div className="flex bg-slate-200 min-h-screen justify-center">
      <FormContainer
        handleSubmit={handleSubmit}
        initialValues={{ password: "", reEnterPassword: "" }}
        validationSchema={validationSchema}
        width={"w-[90%] md:min-w-[400px] px-5 sm:w-[80%] md:w-[60%] lg:[100%]"}
      >
        <FormField
          width={"w-full"}
          label="Password"
          name="password"
          type="text"
          placeholder="Password"
        />
        <FormField
          width={"w-full"}
          label="Re-enter Password"
          name="reEnterPassword"
          type="text"
          placeholder="Re-enter Password"
        />
        <FormButton loading={loading} title={"Submit"} />
      </FormContainer>
    </div>
  );
};

export default ResetPassword;
