import React from "react";
import { useState } from "react";
import * as Yup from "yup";

import FormContainer from "../../components/CustomForm/FormContainer";
import FormField from "../../components/CustomForm/FormField";
import FormImage from "../../components/CustomForm/FormImage";
import CustomToast from "../../components/CustomToast";
import Loader from "../../utils/Loader";
import { getLocalStorageItem } from "../../utils/LocalStorage";
import { useNavigate } from "react-router-dom";
import { ErrorHandler } from "../../utils/ErrorHandler";
import { addEtambTestimonyService } from "../../services/etambTestimonyService";

const validationSchema = Yup.object().shape({
  picture: Yup.string().required().label("Image is required"),
});

const AddEtambTestimony = () => {
  const [loading, setLoading] = useState(false);
  const token = getLocalStorageItem();
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("fullName", values["fullName"]);
    formData.append("testimony", values["testimony"]);
    formData.append("picture", values["picture"]);
    try {
      const { data } = await addEtambTestimonyService(formData, token);
      if (data) {
        navigate("/etambTestimony");
        CustomToast("success", data);
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setLoading(false);
  };
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <FormContainer
          initialValues={{ picture: "" }}
          buttonTitle={"Add Etamb Banner"}
          handleSubmit={handleSubmit}
          validationSchema={validationSchema}
          width={"w-[100%] sm:w-[80%] md:w-[60%] lg:[50%] px-5"}
        >
          <FormField label={"Full Name"} name="fullName" width={"w-full"} />
          <FormField label={"Testimony"} name="testimony" width={"w-full"} />
          <FormImage label={"Image"} name="picture" width={"w-full"} />
        </FormContainer>
      )}
    </div>
  );
};

export default AddEtambTestimony;
