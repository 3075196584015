import React from "react";
import { Formik, Form } from "formik";
import FormButton from "./FormButton";

const FormContainer = ({
  children,
  initialValues,
  handleSubmit,
  validationSchema,
  buttonTitle,
  width,
}) => {
  return (
    <div className="flex flex-col justify-center items-center min-h-[calc(100vh-150px)]">
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        validationSchema={validationSchema}
      >
        {() => (
          <>
            <Form
              className={`flex flex-col bg-[#f8f8f8] shadow-xl flex-wrap ${width}  items-center rounded-lg p-2`}
            >
              {children}
              {buttonTitle && <FormButton title={buttonTitle} />}
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default FormContainer;
