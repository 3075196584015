import moment from "moment";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import FormField from "../../components/CustomForm/FormField";
import FormContainer from "../../components/CustomForm/FormContainer";
import FormButton from "../../components/CustomForm/FormButton";
import FormDatePicker from "../../components/CustomForm/FormDatePicker";
import { Form, Formik } from "formik";

// import CustomToast from "../components/CustomToast";
// import { getLocalStorageItem } from "../helper/LocalStorage";
// import {
//   addKeyToUserService,
//   getKeyAssignedToService,
// } from "../services/userService";
// import { ErrorHandler } from "../helper/ErrorHandler";

const validationSchema = Yup.object().shape({
  name: Yup.string().required().label("Full Name"),
  key: Yup.number()
    .test("len", "Key must be 10 digits", (val) => val?.toString().length == 10)
    .required()
    .label("Key"),
  phoneNumber: Yup.string().required().label("Phone Number"),
  date: Yup.date().required().label("Date"),
});

const EditInvitedUser = () => {
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const [users, setUser] = useState([]);
  const [filter, setFilter] = useState("");
  //   const token = getLocalStorageItem();
  const [state, setState] = useState({
    name: "",
    key: "",
    date: new Date(),
  });

  //   useEffect(() => {
  //     getReferral();
  //   }, []);

  //   const getReferral = async () => {
  //     setLoading(true);
  //     try {
  //       const { data } = await getKeyAssignedToService(token);
  //       if (data.data) {
  //         let newData = data.data;
  //         newData.map((items) => {
  //           items.date = moment(items.data).format("YYYY-MM-DD hh:mm a");
  //           items.registered = items.registered ? "Registered" : "Not registered";
  //         });
  //         setUser(newData);
  //       }
  //     } catch (error) {
  //       ErrorHandler(error).map((e) => CustomToast("Err", e.message));
  //     }
  //     setLoading(false);
  //   };

  const handleSubmit = async (values) => {
    // try {
    //   setAddLoading(true);
    //   const { data } = await addKeyToUserService(values, token);
    //   if (data) {
    //     CustomToast("success", data.message);
    //     setState({ name: "", date: "", key: "" });
    //     setVisible(false);
    //     getReferral();
    //   }
    // } catch (error) {
    //   ErrorHandler(error).map((e) => CustomToast("Err", e.message));
    // }
    // setAddLoading(false);
  };
  return (
    <div className="flex flex-col w-[700px] ">
      <Formik
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        validationSchema={validationSchema}
        initialValues={state}
        handleSubmit={handleSubmit}
      >
        {() => (
          <>
            <Form
              className={`flex flex-col flex-wrap items-center rounded-lg p-2`}
            >
              <div className="flex flex-col items-center w-full ">
                <FormField
                  label="Full Name"
                  name="name"
                  type="text"
                  width={"w-full"}
                />
                <FormField
                  label="Key"
                  width={"w-full"}
                  name="key"
                  type="number"
                />
                <FormField
                  label="Phone Number"
                  width={"w-full"}
                  name="phoneNumber"
                  type="number"
                />
                <FormDatePicker
                  label={"Date of invitation"}
                  name={"date"}
                  time={true}
                  width={"w-full"}
                ></FormDatePicker>
                <div className="flex w-full justify-center">
                  <FormButton loading={addLoading} title={"Edit"}></FormButton>
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default EditInvitedUser;
