import { toast } from "react-toastify";

function CustomToast(status, data) {
  const toastProperty = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    };
  switch (status) {
    case "success":
      return toast.success(data, toastProperty);
    case "warn":
      return toast.warn(data, toastProperty);
    case "info":
      return toast.info(data, toastProperty);
    case "error":
      return toast.error(data, toastProperty);
    default:
      return toast.error(data, toastProperty);
  }
}

export default CustomToast;
