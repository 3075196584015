import React from "react";
import { useState } from "react";
import * as Yup from "yup";

import FormContainer from "../../components/CustomForm/FormContainer";
import FormField from "../../components/CustomForm/FormField";
import FormImage from "../../components/CustomForm/FormImage";
import CustomToast from "../../components/CustomToast";
import Loader from "../../utils/Loader";
import { getLocalStorageItem } from "../../utils/LocalStorage";
import { addBannerService } from "../../services/bannerService";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object().shape({
  picture: Yup.string().required().label("Image is required"),
  description: Yup.string().nullable().label("Description"),
});

const AddBanner = () => {
  const [loading, setLoading] = useState(false);
  const token = getLocalStorageItem();
  const navigate = useNavigate();
  const handleSubmit = async (values) => {
    setLoading(true);
    const formData = new FormData();
    Object.keys(values).forEach((item) => formData.append(item, values[item]));
    try {
      const { data } = await addBannerService(formData, token);
      if (data.data) {
        navigate("/banner");
        CustomToast("success", data.data);
      }
    } catch (error) {
      CustomToast("err", error?.response?.data?.message || error?.message);
    }
    setLoading(false);
  };
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <FormContainer
          initialValues={{ picture: "", description: "" }}
          buttonTitle={"Add Banner"}
          handleSubmit={handleSubmit}
          validationSchema={validationSchema}
          width={"w-[100%] sm:w-[80%] md:w-[60%] lg:[50%] px-5"}
        >
          <FormImage label={"Image"} name="picture" width={"w-full"} />
          <FormField
            type={"text"}
            name={"description"}
            label={"Banner description"}
            as="textarea"
            cols={"20"}
            rows={"20"}
            width={"w-full"}
          ></FormField>
        </FormContainer>
      )}
    </div>
  );
};

export default AddBanner;
