import React from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import FormContainer from "../../components/CustomForm/FormContainer";
import FormField from "../../components/CustomForm/FormField";
import CustomToast from "../../components/CustomToast";

import { getLocalStorageItem } from "../../utils/LocalStorage";
import { addConstantService } from "../../services/constantService";
import { ErrorHandler } from "../../utils/ErrorHandler";

const validationSchema = Yup.object().shape({
  name: Yup.string().required().label("Constant Name"),
  value: Yup.string().required().label("Constant Value"),
});
const AddConstantPage = () => {
  const constant = {
    name: "",
    value: "",
  };
  const navigate = useNavigate();
  const token = getLocalStorageItem();

  const handleAddConstant = async (values) => {
    try {
      const { data } = await addConstantService(values, token);
      if (data) {
        CustomToast("success", "Constant added successfully");
        navigate("/constant");
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
  };

  return (
    <div>
      <FormContainer
        buttonTitle={"Add Constant"}
        initialValues={constant}
        handleSubmit={handleAddConstant}
        validationSchema={validationSchema}
        width={"w-[100%] sm:w-[80%] md:w-[60%] lg:[50%]"}
      >
        <FormField
          type={"text"}
          width={"w-[90%]"}
          name={"name"}
          label={"Constant Name"}
        ></FormField>
        <FormField
          type={"text"}
          width={"w-[90%]"}
          name={"value"}
          label={"Constant Value"}
        ></FormField>
      </FormContainer>
    </div>
  );
};

export default AddConstantPage;
