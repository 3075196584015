import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import FormContainer from "../../components/CustomForm/FormContainer";
import FormField from "../../components/CustomForm/FormField";
import CustomToast from "../../components/CustomToast";
import Loader from "../../utils/Loader";
import { getLocalStorageItem } from "../../utils/LocalStorage";
import { addRoleService } from "../../services/roleService";

const AddRole = () => {
  const role = {
    name: "",
    scoreValue: "",
    fee: "",
    allowedNumber: 0,
  };
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required().label("Role"),
    scoreValue: Yup.number().required().label("Score Value"),
    fee: Yup.string().required().label("Fee"),
    allowedNumber: Yup.number().required().label("Allowed Number"),
  });

  const token = getLocalStorageItem();
  const handleSubmit = async (value) => {
    setLoading(true);
    try {
      const { data } = await addRoleService(value, token);
      if (data.data) {
        CustomToast("success", data.data);
        navigate("/role");
      }
    } catch (error) {
      CustomToast(
        "error",
        error?.response?.data.message || error?.response?.message
      );
    }
    setLoading(false);
  };

  return (
    <div>
      {loading && <Loader />}
      <FormContainer
        buttonTitle={"Add Role"}
        initialValues={role}
        handleSubmit={handleSubmit}
        validationSchema={validationSchema}
        width={"w-[100%] sm:w-[80%] md:w-[60%] lg:[50%]"}
      >
        <FormField
          type={"text"}
          name={"name"}
          label={"Role Name"}
          width={"w-full"}
        ></FormField>
        <FormField
          type={"number"}
          name={"scoreValue"}
          label={"Score Value"}
          width={"w-full"}
        ></FormField>
        <FormField
          type={"text"}
          name={"fee"}
          label={"Fee to join"}
          width={"w-full"}
        ></FormField>
        <FormField
          type={"number"}
          name={"allowedNumber"}
          label={"Allowed Number"}
          width={"w-full"}
        ></FormField>
      </FormContainer>
    </div>
  );
};

export default AddRole;
