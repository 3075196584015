import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import CustomToast from "../../components/CustomToast";
import Loader from "../../utils/Loader";
import { getLocalStorageItem } from "../../utils/LocalStorage";
import {
  getUserInEachRole,
  getUserRequestInEachRole,
} from "../../services/userService";
import MUITable from "../../components/Table";
import CustomPopover from "../../common/Popover";
import CustomDialog from "../../common/Dialog";
import { Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomModalComponent from "../../common/CustomModal/CustomModal";



function UserInEachRole() {
  const params = useParams();
  const [users, setUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const token = getLocalStorageItem();

  const getUserRole = async () => {
    setLoading(true);
    try {
      const { data } = await getUserInEachRole(params.roleId, token);
      if (data.data.length) {
        let temp = data.data;
        temp.map((item) => {
          if (item.isApproved) item.isApproved = "Approved";
          else item.isApproved = "Not Approved";
        });
        setUser(temp);
      }
    } catch (error) {
      CustomToast("failed", error?.response?.data.message || error?.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    getUserRole();
  }, []);

  const columns = [
    { label: "First Name", value: "firstName" },
    { label: "Last Name", value: "lastName" },
    { label: "Phone Number", value: "phoneNumber" },
    { label: "Approved", value: "isApproved" },
    { label: "Agent Key", value: "agentKey" },
  ];

  return (
    <div className="px-10">
      {loading ? (
        <Loader />
      ) : (
        <MUITable
          columns={columns}
          data={users}
          path={state.path}
          type={state.path[1] == "u" ? "user" : "approve"}
        />
      )}
    </div>
  );
}

export default UserInEachRole;
