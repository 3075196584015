import { Field } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import FormContainer from "../../components/CustomForm/FormContainer";
import FormField from "../../components/CustomForm/FormField";
import CustomToast from "../../components/CustomToast";
import Loader from "../../utils/Loader";
import { getLocalStorageItem } from "../../utils/LocalStorage";
import {
  getOneEtambMessageService,
  editEtambMessageService,
} from "../../services/etambMessageService";
import { ErrorHandler } from "../../utils/ErrorHandler";

const EditEtambMessage = () => {
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);

  const { state } = useLocation();
  const { id } = state;
  const token = getLocalStorageItem();

  const getMessage = async () => {
    setLoading(true);
    try {
      const { data } = await getOneEtambMessageService(id, token);
      if (data) setMessage(data);
    } catch (error) {
      CustomToast("err", error?.response?.data?.message || error?.message);
    }
    setLoading(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const { data } = await editEtambMessageService(
        { id, isContacted: true },
        token
      );
      console.log(data);
      if (data) CustomToast("success", "Contact Us message updated");
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    getMessage();
    return () => {
      setMessage({});
    };
  }, []);
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <FormContainer
          buttonTitle={"Resolve"}
          width={"w-[90%] md:w-[50%] "}
          handleSubmit={handleSubmit}
          initialValues={{
            email: message?.email ?? "",
            message: message?.message ?? "",
            createdAt: message.createdAt ?? "",
            fullName: message.fullName ?? "",
          }}
        >
          <FormField
            label="Full Name"
            name="fullName"
            width={"w-[90%]  md:px-5 my-1"}
            disabled={true}
            isLogin={true}
          ></FormField>
          <FormField
            label="Email"
            name="email"
            width={"w-[90%]  md:px-5 my-1"}
            disabled={true}
            isLogin={true}
          ></FormField>
          <FormField
            label="Your message"
            name="message"
            disabled={true}
            as="textarea"
            cols={"10"}
            rows={"10"}
            width={"w-[90%]  md:px-5 my-1"}
            isLogin={true}
          ></FormField>
          <p className="w-[90%] md:px-5 p-2 my-2 text-[#4D5959] text-left">
            Requested on
          </p>
          <Field
            disabled={true}
            className="w-[90%] md:px-5 my-1 outline-[#99d5e9] rounded-md p-2 bg-[#EFF0F2]"
            value={new Date(message.createdAt).toDateString()}
          ></Field>
        </FormContainer>
      )}
    </div>
  );
};

export default EditEtambMessage;
