import React, { useEffect, useState } from "react";
import {
  getCurrentJobService,
  getRoleModelService,
  getSkillService,
  getVolunteerService,
} from "../../../services/AdditionalService";
import CustomToast from "../../../components/CustomToast";
import { ErrorHandler } from "../../../utils/ErrorHandler";
import MUITable from "../../../components/Table";
import { getLocalStorageItem } from "../../../utils/LocalStorage";
import Loader from "../../../utils/Loader";

const RoleModel = () => {
  const [role, setRole] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = getLocalStorageItem();

  const getRoleModel = async () => {
    setLoading(true);
    try {
      const { data } = await getRoleModelService(token);
      if (data) {
        let temp = [...data];
        temp.map((items) => {
          items.firstName = items?.user?.firstName;
          items.lastName = items?.user?.lastName;
          items.userId = items.user_id;
        });
        setRole(temp);
      }

      setLoading(false);
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
      setLoading(false);
    }
  };

  useEffect(() => {
    getRoleModel();
  }, []);
  const columns = [
    {
      label: "Family Name",
      value: "familyName",
    },
    { label: "National Name", value: "nationalName" },
    { label: "International Name", value: "internationalName" },
    { label: "First Name", value: "firstName" },
    { label: "Last Name", value: "lastName" },
  ];

  return (
    <div className="px-10 w-full">
      {loading ? (
        <Loader />
      ) : (
        <MUITable
          path={"/userManagment/userInEachRole/userDetail"}
          type={"user"}
          columns={columns}
          data={role}
        />
      )}
    </div>
  );
};

export default RoleModel;
