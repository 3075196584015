import * as React from "react";
import { useFormikContext, ErrorMessage } from "formik";
import AdapterMoment from "@date-io/moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";



export default function FormDatePicker({ label, name, width }) {
  const { values,setFieldValue } = useFormikContext();
  return (
    <div className={`${width} my-2.5`}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          value={values[name]}
          className="w-full md:px-5 text-[14px] p-[2px]  hover:outline-none outline-[#99d5e9] rounded-md my-2 bg-[#EFF0F2]"
          label={label}
          onChange={(newValue) => {
            setFieldValue(name, newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
        <ErrorMessage
          className="text-[#e95f5f] text-left text-[14px]"
          name={name}
          component="p"
        />
      </LocalizationProvider>
    </div>
  );
}
