import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";

import CustomToast from "../../components/CustomToast";
import { editShareService } from "../../services/shareService";
import FormContainer from "../../components/CustomForm/FormContainer";
import FormField from "../../components/CustomForm/FormField";
import FormDropDown from "../../components/CustomForm/FormDropDown";
import Loader from "../../utils/Loader";
import { getLocalStorageItem } from "../../utils/LocalStorage";

const validationSchema = Yup.object().shape({
  amount: Yup.number().required().label("Amount"),
  percentage: Yup.string().required().label("Percentage"),
  role: Yup.string().required().label("Role"),
});
const EditSharePage = () => {
  const { state } = useLocation();
  const { amount, percentage, role, id } = state;

  const [loading, setLoading] = useState(false);
  const [share, setShare] = useState({
    amount: amount,
    percentage: percentage,
    role: role,
  });
  const token = getLocalStorageItem();

  const handleEdit = async (value) => {
    setLoading(true);
    try {
      const { data } = await editShareService(id, value, token);
      if (data) {
        setShare({
          amount: data.data.amount,
          percentage: data.data.percentage,
          role: data.data.role,
        });
        CustomToast("success", data?.message);
      }
    } catch (error) {
      CustomToast(
        "error",
        error?.response?.data ? error?.response?.data?.message : error?.message
      );
    }
    setLoading(false);
  };
  return (
    <div>
      {loading && <Loader />}
      <FormContainer
        buttonTitle={"Edit Share"}
        initialValues={share}
        handleSubmit={handleEdit}
        validationSchema={validationSchema}
        width={"w-[90%] sm:w-[80%] md:w-[60%] lg:[50%]"}
      >
        <FormField
          type={"number"}
          name={"amount"}
          label={"Amount"}
          width={"w-full"}
        ></FormField>
        <FormField
          type={"text"}
          name={"percentage"}
          label={"Percentage"}
          width={"w-full"}
        ></FormField>
      </FormContainer>
    </div>
  );
};

export default EditSharePage;
