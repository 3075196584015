import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import FormContainer from "../../components/CustomForm/FormContainer";
import FormField from "../../components/CustomForm/FormField";

import CustomToast from "../../components/CustomToast";
import Loader from "../../utils/Loader";
import { addBankService } from "../../services/bankService";

const AddBankPage = () => {
  const bank = {
    name: "",
    accountNumber: "",
  };
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required().label("Bank Name"),
    accountNumber: Yup.string().required().label("Account Number"),
  });
  const navigate = useNavigate();

  const handleSubmit = async (value) => {
    setLoading(true);
    try {
      const { data } = await addBankService(value);
      if (data.data) {
        CustomToast("success", data.data);
        navigate("/bank");
      }
    } catch (error) {
      CustomToast(
        "error",
        error?.response?.data.message || error?.response?.message
      );
    }
    setLoading(false);
  };

  return (
    <div>
      {loading && <Loader />}
      <FormContainer
        buttonTitle={"Add Bank"}
        initialValues={bank}
        handleSubmit={handleSubmit}
        validationSchema={validationSchema}
        width={"w-[90%] sm:w-[80%] md:w-[50%] lg:[40%]"}
      >
        <FormField
          type={"text"}
          name={"name"}
          label={"Bank Name"}
          width={"w-full"}
        ></FormField>
        <FormField
          type={"text"}
          name={"accountNumber"}
          label={"Account Number"}
          width={"w-full"}
        ></FormField>
      </FormContainer>
    </div>
  );
};

export default AddBankPage;
