import axios from "axios";
import { URL } from "../config/config";
import { getLocalStorageItem } from "../utils/LocalStorage";

const header = () => {
  return {
    headers: {
      "x-auth-token": getLocalStorageItem(),
    },
  };
};

export const getAllBankService = async (token) => {
  return await axios.get(`${URL}banks`, header());
};
export const getSpecificBankInfoService = async (id, token) => {
  return await axios.get(`${URL}bank/${id}`, header());
};
export const addBankService = async (value, token) => {
  return await axios.post(`${URL}bank`, value, header());
};
export const editBankService = async (id, value, token) => {
  return await axios.put(`${URL}bank/${id}`, value, header());
};
export const deleteBankService = async (id, token) => {
  return await axios.delete(`${URL}deleteBank/${id}`, header());
};
