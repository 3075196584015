import React, { useEffect, useState } from "react";

import CustomToast from "../../components/CustomToast";
import CustomBox from "../../utils/CustomBox";
import Loader from "../../utils/Loader";
import { getLocalStorageItem } from "../../utils/LocalStorage";

import { getUserCount } from "../../services/adminService";

function UserManagement() {
  const [role, setRole] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllUser();
  }, []);
  const token = getLocalStorageItem();
  const getAllUser = async () => {
    setLoading(true);
    try {
      const { data } = await getUserCount(token);
      if (data) setRole(data);
    } catch (error) {
      CustomToast("failed", error?.response?.data.message || error?.message);
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-col w-full">
      {loading ? (
        <Loader />
      ) : (
        <div className="flex flex-col items-center">
          <div className="flex flex-wrap pl-[10%] justify-start w-[100%] items-center">
            {role.map((roles, index) => (
              <CustomBox
                key={index}
                to={`/userManagment/userInEachRole/${roles.roleId}`}
                state={{ path: "/userManagment/userInEachRole/userDetail" }}
                title={roles.role}
                detail={roles.length}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default UserManagement;
