import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getLocalStorageItem } from "../../utils/LocalStorage";
import {
  addBannerService,
  editBannerService,
  getOneBannerService,
} from "../../services/bannerService";
import CustomToast from "../../components/CustomToast";
import FormContainer from "../../components/CustomForm/FormContainer";

import FormImage from "../../components/CustomForm/FormImage";
import FormField from "../../components/CustomForm/FormField";
import { BASE_URL } from "../../config/config";
import Loader from "../../utils/Loader";

const EditBanner = () => {
  const [banner, setBanner] = useState({});
  const [loading, setLoading] = useState(false);

  const { state } = useLocation();
  const token = getLocalStorageItem();

  const getBanner = async () => {
    setLoading(true);
    try {
      const { data } = await getOneBannerService(state.id, token);
      if (data.data) setBanner(data.data[0]);
    } catch (error) {
      CustomToast("Err", error?.response?.data?.message ?? error?.message);
    }
    setLoading(false);
  };
  const handleSubmit = async (values) => {
    setLoading(true);
    const formData = new FormData();
    Object.keys(values).forEach((item) => formData.append(item, values[item]));
    try {
      const { data } = await editBannerService(formData, token);
      if (data.data) {
        setBanner(data.data);
        CustomToast("success", data.message);
      }
    } catch (error) {
      CustomToast("err", error?.response?.data?.message || error?.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    getBanner();
  }, []);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <FormContainer
          initialValues={banner}
          buttonTitle={"Edit Banner"}
          handleSubmit={handleSubmit}
          width={"w-[100%] sm:w-[80%] md:w-[60%] lg:[50%] px-5"}
        >
          <img src={BASE_URL + banner?.image} width={"100%"}></img>

          <FormImage label={"Change Image"} name="picture" width={"w-full"} />
          <FormField
            type={"text"}
            name={"description"}
            label={"Banner description"}
            as="textarea"
            cols={"20"}
            rows={"20"}
            width={"w-full"}
          ></FormField>
        </FormContainer>
      )}
    </div>
  );
};

export default EditBanner;
