import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { getLocalStorageItem } from "../../utils/LocalStorage";
import CustomToast from "../../components/CustomToast";
import FormContainer from "../../components/CustomForm/FormContainer";

import FormImage from "../../components/CustomForm/FormImage";
import FormField from "../../components/CustomForm/FormField";
import { BASE_URL } from "../../config/config";
import Loader from "../../utils/Loader";
import {
  editEtambBannerService,
  getOneEtambBannerService,
} from "../../services/etambBannerService";
import { ErrorHandler } from "../../utils/ErrorHandler";
const validationSchema = Yup.object().shape({
  picture: Yup.string().required().label("Image is required"),
});
const EditEtambBanner = () => {
  const [banner, setBanner] = useState({});
  const [loading, setLoading] = useState(false);

  const { state } = useLocation();
  const navigation = useNavigate();
  const token = getLocalStorageItem();

  const getBanner = async () => {
    setLoading(true);
    try {
      const { data } = await getOneEtambBannerService(state.id, token);
      console.log(data);
      if (data) setBanner(data);
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setLoading(false);
  };

  const handleSubmit = async (values) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("id", state.id);
    formData.append("picture", values["picture"]);
    try {
      const { data } = await editEtambBannerService(formData, token);
      if (data) {
        CustomToast("success", "Etamb Banner Update");
        navigation("/etambBanner");
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    getBanner();
  }, []);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <FormContainer
          initialValues={{ picture: "" }}
          buttonTitle={"Edit Banner"}
          handleSubmit={handleSubmit}
          width={"w-[100%] sm:w-[80%] md:w-[60%] lg:[50%] px-5"}
          validationSchema={validationSchema}
        >
          <img src={BASE_URL + banner?.image} width={"100%"}></img>

          <FormImage label={"Change Image"} name="picture" width={"w-full"} />
        </FormContainer>
      )}
    </div>
  );
};

export default EditEtambBanner;
