import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import FormContainer from "../../components/CustomForm/FormContainer";
import FormField from "../../components/CustomForm/FormField";
import FormDatePicker from "../../components/CustomForm/FormDatePicker";
import Loader from "../../utils/Loader";
import { addPaymentDetailService } from "../../services/paymentService";
import CustomToast from "../../components/CustomToast";
import { getLocalStorageItem } from "../../utils/LocalStorage";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required().label("First name"),
  lastName: Yup.string().required().label("Last name"),
  amount: Yup.string().required().label("Amount to be paid"),
  bank: Yup.string().required().label("Bank"),
  bankAccountNo: Yup.string().required().label("Account Number"),
  reference: Yup.string().required().label("Transaction Number"),
  date: Yup.date().required().label("Date of event"),
});
const Pay = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const user = {
    firstName: state.firstName,
    lastName: state.lastName,
    amount: state.amount,
    bank: state.bank,
    bankAccountNo: state.bankAccountNo,
    reference: state.reference ?? "",
    date: state.date ?? "",
  };
  const [loading, setLoading] = useState(false);

  const token = getLocalStorageItem();
  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const formData = {
        id: state?.id,
        reference: values?.reference,
        date: values?.date,
      };
      const { data } = await addPaymentDetailService(formData, token);
      if (data.data) {
        navigate("/payment");
        CustomToast("success", data.data);
      }
    } catch (error) {
      CustomToast("err", error?.response?.data?.message);
    }
    setLoading(false);
  };

  return (
    <div>
      {loading && <Loader />}
      <FormContainer
        buttonTitle={"Pay"}
        initialValues={user}
        handleSubmit={handleSubmit}
        validationSchema={validationSchema}
        width={"w-[90%] sm:w-[80%] md:w-[60%] lg:[50%]"}
      >
        <FormField
          type={"text"}
          name={"firstName"}
          label={"First Name"}
          width={"w-full"}
          disabled={true}
        ></FormField>
        <FormField
          type={"text"}
          name={"lastName"}
          label={"Last Name"}
          width={"w-full"}
          disabled={true}
        ></FormField>
        <FormField
          type={"text"}
          name={"amount"}
          label={"Payable Amount"}
          width={"w-full"}
          disabled={true}
        ></FormField>
        <FormField
          type={"text"}
          name={"bank"}
          label={"Bank"}
          width={"w-full"}
          disabled={true}
        ></FormField>
        <FormField
          type={"text"}
          name={"bankAccountNo"}
          label={"Account Number"}
          width={"w-full"}
          disabled={true}
        ></FormField>
        <FormField
          type={"text"}
          name={"reference"}
          label={"Reference Number"}
          width={"w-full"}
        ></FormField>

        <FormDatePicker
          name={"date"}
          title={"Payment Date"}
          width={"w-full"}
        ></FormDatePicker>
      </FormContainer>
    </div>
  );
};

export default Pay;
