import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

import CustomToast from "../../components/CustomToast";
import ListInformation from "../../components/ListInformation";
import { approveUser, clearFormService } from "../../services/adminService";
import { getUserPaymentInformationService } from "../../services/userService";
import Loader from "../../utils/Loader";
import { getLocalStorageItem } from "../../utils/LocalStorage";
import { Button, Popover } from "@mui/material";
import { ErrorHandler } from "../../utils/ErrorHandler";
import CustomImageWithLabel from "../../components/CustomImageWithLabel";
import { BASE_URL } from "../../config/config";

function UserDetailForG3() {
  const { state } = useLocation();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const token = getLocalStorageItem();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getUser = async () => {
    setLoading(true);
    try {
      const { data } = await getUserPaymentInformationService(
        state?.id,
        state.role,
        token
      );
      if (data) {
        setUser(data[0]);
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e?.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    getUser();
    return () => {};
  }, []);

  const handleApprove = async () => {
    setLoading(true);
    try {
      const { data } = await approveUser(
        { id: state.userId, role: state.role },
        token
      );
      if (data) {
        CustomToast("success", "User approved!");
        navigate("/approveUser", { replace: true });
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e?.message));
    }
    setLoading(false);
  };

  const handleClearForm = async () => {
    setLoading(true);
    try {
      const { data } = await clearFormService(
        { userId: user.user_id, paymentID: user.paymentID },
        token
      );
      if (data.data) {
        CustomToast("success", data.data);
        navigate("/approveUser");
        handleClose();
      }
    } catch (error) {
      handleClose();
      ErrorHandler(error).map((e) => CustomToast("error", e?.message));
    }
    setLoading(false);
  };
  
  const userInfo = user?.user;
  return (
    <div>
      <div className="flex flex-col px-5 md:px-10">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="flex flex-col md:flex-row flex-wrap md:justify-between">
              {userInfo?.firstName && (
                <ListInformation label="Name" value={userInfo?.firstName} />
              )}
              {userInfo?.email && (
                <ListInformation label="Email" value={userInfo?.email} />
              )}
              {userInfo?.lastName && (
                <ListInformation
                  label="Father Name"
                  value={userInfo?.lastName}
                />
              )}
              {user?.paymentAmount && (
                <ListInformation
                  label="Payment Amount"
                  value={user?.paymentAmount}
                />
              )}
              {user?.paymentReferenceNo && (
                <ListInformation
                  label="Payment Reference Number"
                  value={user?.paymentReferenceNo}
                />
              )}
              {user?.bank && (
                <ListInformation label="Bank" value={user?.bank} />
              )}
              {user?.paymentDate && (
                <ListInformation
                  label="Payment Date (DD-MM-YYYY)"
                  value={moment(user?.paymentDate).format("ddd DD/MM/YYYY")}
                />
              )}
              <div className="w-full flex flex-col md:flex-row justify-between">
                {!!userInfo?.document && (
                  <div className="flex flex-col cursor-pointer w-full md:w-[50%] ">
                    {user?.document?.slice(-3) == "pdf" ? (
                      <a
                        target={"_blank"}
                        className="w-[100%]"
                        href={`${BASE_URL}${user.document}`}
                      >
                        <ListInformation
                          disabled={true}
                          label="Document"
                          name="document"
                          value={"View document"}
                        />
                      </a>
                    ) : (
                      <CustomImageWithLabel
                        picture={userInfo?.document}
                        label={"Document"}
                        height={300}
                        width={"w-full md:w-[45%]"}
                        imageWidth={"w-full "}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="flex px-10 my-2 justify-between w-[100%] md:w-[50%] max-w-[300px]">
              <button
                onClick={handleApprove}
                className="bg-green-500 w-max min-w-[80px] rounded-md cursor-pointer text-[14px] md:text-[16px] text-white p-0.5 md:p-1"
              >
                Approve
              </button>
              <button
                onClick={handleClick}
                className="bg-red-500 w-max min-w-[80px] rounded-md cursor-pointer text-[14px] md:text-[16px] text-white p-0.5 md:p-1"
              >
                Clear
              </button>
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl && anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <div className="px-5 p-2.5">
                  <p>Are you sure you want to clear the user info?</p>
                  <div className="flex justify-evenly my-2">
                    <Button
                      color="success"
                      size="small"
                      variant="contained"
                      onClick={handleClearForm}
                    >
                      Yes
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      color="error"
                      onClick={handleClose}
                    >
                      No
                    </Button>
                  </div>
                </div>
              </Popover>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default UserDetailForG3;
