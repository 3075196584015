import React, { useState, useEffect } from "react";
import { Form, Formik } from "formik";
import { PriorityHigh } from "@mui/icons-material";
import { MenuItem, Select } from "@mui/material";
import * as Yup from "yup";

import FormButton from "../../../components/CustomForm/FormButton";
import CustomToast from "../../../components/CustomToast";
import { getAllFieldService } from "../../../services/fieldService";
import { addG2Service } from "../../../services/adminService";
import { getAllBankService } from "../../../services/bankService";
import { getAllRoleService } from "../../../services/roleService";
import { getShareForRoleService } from "../../../services/shareService";
import G2 from "../Roles/G2";
import G3 from "../Roles/G3";
import G4 from "../Roles/G4";
import Share from "../Roles/Share";
import { ErrorHandler } from "../../../utils/ErrorHandler";
import Loader from "../../../utils/Loader";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required().label("First Name"),
  phoneNumber: Yup.string().required().label("Phone Number"),
  lastName: Yup.string().required().label("Father Name"),
  grandFatherName: Yup.string().required().label("Grand Father Name"),
  country: Yup.string().required().label("Country"),
  state: Yup.string().required().label("State"),
  city: Yup.string().required().label("City"),
  university: Yup.string().required().label("Education Level"),
  field_id: Yup.string().required().label("Field"),
  yearGraduated: Yup.string().required().label("Year graduated"),
  document: Yup.mixed()
    .required()
    .test(
      "FILE_TYPE",
      "Invalid file type, Only images and pdf is allowed",
      (value) =>
        !value ||
        (value &&
          ["image/png", "image/jpg", "image/jpeg", "application/pdf"].includes(
            value.type
          ))
    )
    .test(
      "FILE_SIZE",
      "File size is too large, please upload file less than 5 Mega Bytes",
      (value) => !value || (value && value.size <= 5000000)
    )
    .label("Document"),
  referal: Yup.string().required().label("This field"),
  referalCode: Yup.string().when("referal", {
    is: "agent",
    then: Yup.string().required().label("Referal code"),
    otherwise: Yup.string().nullable(),
  }),
  serialKey: Yup.number()
    .label("Key")
    .when("referal", {
      is: "agent",
      then: Yup.number()
        .test(
          "len",
          "Key must be 10 digits",
          (val) => val?.toString().length == 10
        )
        .required(),
      otherwise: Yup.number(),
    }),
});

const AddUser = () => {
  const [field, setField] = useState([]);
  const [bank, setBank] = useState([]);
  const [bankLoading, setBankLoading] = useState(false);
  const [shareLoading, setShareLoading] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  const [fieldLoading, setFieldLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [share, setShare] = useState(null);
  const [progressVisible, setProgressVisible] = useState(false);
  const [registerLoading, setRegisterLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [roles, setRoles] = useState([]);
  const initialValue = {
    firstName: "",
    email: "",
    phoneNumber: "",
    password: "",
    lastName: "",
    grandFatherName: "",
    country: "",
    state: "",
    city: "",
    university: "",
    field_id: "",
    yearGraduated: "",
    document: [],
    referal: "",
    referalCode: "",
    serialKey: 0,
  };

  const getField = async () => {
    setFieldLoading(true);
    try {
      const { data } = await getAllFieldService();
      if (data) setField(data.data);
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setFieldLoading(false);
  };

  const getRoles = async () => {
    setRoleLoading(true);
    try {
      const { data } = await getAllRoleService();
      if (data) setRoles(data.data);
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setRoleLoading(false);
  };

  const getBank = async () => {
    setBankLoading(true);
    try {
      const { data } = await getAllBankService();
      if (data) setBank(data.data);
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setBankLoading(false);
  };

  const getShare = async () => {
    setShareLoading(true);
    try {
      const { data } = await getShareForRoleService(selectedRole);
      if (data) setShare(data.data);
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setShareLoading(false);
  };

  useEffect(() => {
    getField();
    getBank();
    getRoles();
    return () => {
      setField([]);
      setSelectedRole([]);
      setShare([]);
    };
  }, []);

  useEffect(() => {
    if (selectedRole > 4) getShare();
    return () => {
      setShare([]);
    };
  }, [selectedRole]);

  const clearProgress = () => {
    setProgressVisible(false);
    setProgress(0);
  };

  const handleRegister = async (values) => {
    const formData = new FormData();
    values.role_id = selectedRole;
    values[`upgradeDateForG${selectedRole}`] = new Date().toISOString();
    values.shareId = share?.id;
    values.paymentAmount =
      selectedRole < 5
        ? roles.find((role) => role.id == selectedRole).fee
        : values.paymentAmount;

    Object.keys(values).forEach((item) => formData.append(item, values[item]));
    setRegisterLoading(true);
    try {
      const { data } = await addG2Service(formData, (progress) => {
        setProgressVisible(true);
        setProgress(Math.ceil(progress * 100));
      });
      if (data) {
        CustomToast("success", "User Added Successfully");
        clearProgress();
      }
    } catch (error) {
      clearProgress();
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setRegisterLoading(false);
  };

  return (
    <div className="flex flex-col items-center min-h-[400px]">
      {bankLoading || roleLoading || fieldLoading || shareLoading ? (
        <div className="flex w-full justify-center">
          <Loader />
        </div>
      ) : (
        <>
          <Select
            id="role-select"
            value={selectedRole}
            label="Role"
            onChange={(e) => setSelectedRole(e.target.value)}
            sx={{ width: "50%" }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {roles.map((items) => (
              <MenuItem key={items.id} value={items.id}>
                {items.name}
              </MenuItem>
            ))}
          </Select>

          {selectedRole ? (
            <Formik
              initialValues={initialValue}
              onSubmit={handleRegister}
              validationSchema={validationSchema}
            >
              {({ isSubmitting, values }) => (
                <>
                  <Form
                    className={`w-full p-2.5 justify-between flex flex-wrap `}
                  >
                    <G2
                      country={values.country}
                      field={field}
                      progress={progress}
                      progressVisible={progressVisible}
                    ></G2>
                    {selectedRole === 3 && <G3 bank={bank} />}
                    {selectedRole > 3 && (
                      <G4
                        role={selectedRole}
                        bank={bank}
                        progress={progress}
                        progressVisible={progressVisible}
                      />
                    )}
                    {selectedRole > 4 && <Share roleId={selectedRole} />}

                    <div className="w-full flex justify-end mt-5">
                      <FormButton
                        title={"Add User"}
                        isSubmitting={
                          isSubmitting ||
                          registerLoading ||
                          progressVisible ||
                          fieldLoading ||
                          bankLoading ||
                          roleLoading ||
                          shareLoading
                        }
                      />
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          ) : (
            <>
              <PriorityHigh
                fontSize="inherit"
                sx={{ fontSize: 80, color: "blueviolet" }}
              />
              <p>Please Select a Role</p>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default AddUser;
