import React, { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import "../App.css";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "./ErrorMessage";

const MUITable = ({ columns, data, path, type, state }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterValue, setFilterValue] = useState("");
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredData = data?.filter((row) => {
    const values = Object.values(row);
    return values?.some((value) =>
      value?.toString()?.toLowerCase().includes(filterValue?.toLowerCase())
    );
  });

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);
  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
    setPage(0);
  };

  return (
    <div className="flex flex-col">
      {data?.length ? (
        <>
          <TextField
            value={filterValue}
            onChange={handleFilterChange}
            label="Filter"
            variant="outlined"
            margin="normal"
            fullWidth={false}
            style={{
              textAlign: "left",
              width: "50%",
              maxWidth: 250,
            }}
          />
          <Box
            border={0.5}
            borderColor={"lightgrey"}
            borderRadius={2}
            overflow={"auto"}
          >
            <Table size="medium" sx={{ minWidth: 650 }}>
              <TableHead
                sx={{
                  backgroundColor: "darkslategray",
                }}
              >
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: 16,
                        borderRight: "1px solid white",
                      }}
                      key={index}
                    >
                      <Box
                        width={"150px"}
                        style={{ paddingTop: 10, paddingBottom: 10 }}
                      >
                        {column.label}
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData.map((row, index) => (
                  <TableRow
                    sx={{
                      cursor: "pointer",
                      ":hover": { backgroundColor: "lightgrey" },
                    }}
                    key={index}
                  >
                    {columns.map((column, index) => (
                      <TableCell
                        onClick={() => {
                          if (type === "user" && row.userId)
                            navigate(path, {
                              state: {
                                ...row,
                                id: row.userId,
                                type,
                                role: row.requestFor,
                              },
                            });
                          else if (type == "user" && !row.userId) return null;
                          else if (type !== "user" && row.userId)
                            navigate(path, {
                              state: {
                                ...row,
                                id: row.userId,
                                type,
                                role: row.requestFor,
                              },
                            });
                          else
                            navigate(path, {
                              state: {
                                ...row,
                                id: row.id,
                                type,
                                role: row.requestFor,
                              },
                            });
                        }}
                        style={{
                          color: "black",
                          fontSize: 16,
                          borderRight: "1px solid black",
                          width: 300,
                          padding: 10,
                        }}
                        key={index}
                      >
                        {row[column.value]}
                     
                      </TableCell>
                      
                      ))}
                
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <TablePagination
              labelRowsPerPage=""
              rowsPerPageOptions={[10, 15]}
              component="div"
              count={data?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              style={{ overflow: "hidden" }}
            />
          </Box>
        </>
      ) : (
        <ErrorMessage error={"No data"} />
      )}
    </div>
  );
};

export default MUITable;
