import React, { useEffect, useState } from "react";
import moment from "moment";
import { useLocation } from "react-router-dom";

import { getPaymentDetailService } from "../../services/paymentService";
import { getLocalStorageItem } from "../../utils/LocalStorage";
import CustomToast from "../../components/CustomToast";
import Loader from "../../utils/Loader";
import CustomTable from "../../components/Table";
import ErrorMessage from "../../components/ErrorMessage";

const PaymentDetail = () => {
  const { state } = useLocation();
  const [payment, setPayment] = useState([]);
  const [paymentReq, setPaymentReq] = useState([]);
  const [loading, setLoading] = useState(false);

  const getPaymentDetail = async () => {
    setLoading(true);
    try {
      const token = await getLocalStorageItem();
      const { data } = await getPaymentDetailService(state.id, token);
      if (data.data) {
        data?.data?.payment?.map((item) => {
          if (item.isPaid) item.isPaid = "Paid";
          else item.isPaid = "Not Paid";
          item.date = moment(item?.date).format("YYYY-MM-DD");
        });

        setPaymentReq(data.data.payment);
        let tempData = [];
        tempData.push(data.data);
        setPayment(tempData);
      }
    } catch (error) {
      CustomToast("err", error?.response?.data?.message || error?.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    getPaymentDetail();
  }, [state.id]);

  const PaymentColumns = [
    { label: "Amount", value: "amount" },
    { label: "Bank", value: "bank" },
    {
      label: "Bank Account No",
      value: "bankAccountNo",
    },
    {
      label: "Date",
      value: "date",
    },
    {
      label: "Is Paid",
      value: "isPaid",
    },
    {
      label: "Reference",
      value: "reference",
    },
  ];
  const pointColumns = [
    { label: "Total Point", value: "totalPoint" },
    {
      label: "Retained Value",
      value: "retainedValue",
    },
    {
      label: "Withdrawal Percentage",
      value: "withdrawalPercentage",
    },
    {
      label: "Agent Point",
      value: "V8",
    },
    {
      label: "Minimum Withdrawal Amount",
      value: "minimumWithdrawalAmount",
    },
    {
      label: "Available Point",
      value: "availableValue",
    },
    { label: "Pending Value", value: "PV" },
  ];

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <>
            <div className="my-5 px-10">
              <p className="text-slate-500 text-[14px] sm:text-[18px] md:text-[20px] font-semibold  text-left">
                Points
              </p>
              {payment ? (
                <CustomTable columns={pointColumns} data={payment} />
              ) : (
                <ErrorMessage error={"Couldn't fetch point information"} />
              )}
            </div>
          </>
          <>
            <div className="my-5 px-10">
              <p className="text-slate-500 text-[14px] sm:text-[18px] md:text-[20px] font-semibold  text-left">
                Payment Details
              </p>
              <CustomTable columns={PaymentColumns} data={paymentReq} />
            </div>
          </>
          <>
            <div className="my-5 px-10">
              <p className="text-slate-500 mt-5 text-[14px] sm:text-[18px] md:text-[20px] font-semibold text-left">
                Intrim Payment
              </p>

              <table className="table-auto justify-center border-slate-300 w-[100%]">
                <thead className="bg-slate-500 w-[100%]">
                  <tr>
                    <th className="border font-normal text-[12px] sm:text-[14px] md:text-[16px] text-white p-2 border-slate-600">
                      Settled
                    </th>
                    <th className="border font-normal text-[12px] sm:text-[14px] md:text-[16px] text-white p-2 border-slate-600">
                      Pending
                    </th>
                    <th className="border font-normal text-[12px] sm:text-[14px] md:text-[16px] text-white p-2 border-slate-600">
                      Settled + Pending + Last
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="cursor-pointer  bg-slate-200 hover:bg-slate-300">
                    <td className="border border-slate-800 text-center text-slate-800 p-2 text-[12px] sm:text-[14px] md:text-[16px]">
                      {payment[0]?.settled}
                    </td>
                    <td className="border border-slate-800 text-center text-slate-800 p-2 text-[12px] sm:text-[14px] md:text-[16px]">
                      {payment[0]?.PV}
                    </td>
                    <td className="border border-slate-800 text-center text-slate-800 p-2 text-[12px] sm:text-[14px] md:text-[16px]">
                      {parseInt(payment[0]?.settled) + parseInt(payment[0]?.PV)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        </>
      )}
    </div>
  );
};

export default PaymentDetail;
