import * as React from "react";
import { Box, Modal, useMediaQuery } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

export default function CustomModalComponent({ children, open, handleClose }) {
  const theme = createTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const getModalStyle = () => {
    if (xs) return { width: "90%", maxHeight: "500x" };
    else if (sm) return { width: "90%", maxHeight: "600px" };
    else if (md) return { width: "90%", maxHeight: "600px" };
    else return { width: "70%", maxWidth: "900px" };
  };
 
  const modalContentStyle = {
    padding: theme.spacing(2),
    maxHeight: "600px",
    overflowY: "auto",
    borderRadius: "5px",
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "10px",
      border: "3px solid white",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#555",
    },
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    boxShadow: 24,
    borderRadius: '10px',
    maxHeight: '700px', 
    overflow: 'hidden', 
    ...getModalStyle(),
  };


  
  return (
    <ThemeProvider theme={theme}>
      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <Box
            boxShadow="none"
            borderColor={"transparent"}
            sx={modalContentStyle}
          >
            {children}
          </Box>
        </Box>
      </Modal>
    </ThemeProvider>
  );
}
