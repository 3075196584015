// import React, { useEffect, useState, useContext } from "react";
// import MenuIcon from "@mui/icons-material/Menu";
// import { useNavigate } from "react-router-dom";

// import Sidebar from "./sidebar";
// import AuthContext from "../context/AuthContext";
// import { removeLocalStorageItem } from "../utils/LocalStorage";

// function Header({ children }) {
//   const navigate = useNavigate();
//   const { user, setUser } = useContext(AuthContext);
//   const [winWidth, setWinWidth] = useState(window.innerWidth);
//   const [showNavBar, setShowNavBar] = useState("hidden");

//   useEffect(() => {
//     window.addEventListener("resize", () => {
//       setWinWidth(window.innerWidth);
//     });

//     return () => {
//       window.removeEventListener("resize", () => {
//         setWinWidth(0);
//       });
//     };
//   }, [winWidth]);

//   const handleSideBar = () => {
//     showNavBar === "hidden" ? setShowNavBar("block") : setShowNavBar("hidden");
//   };
//   const handleLogout = () => {
//     removeLocalStorageItem();
//     setUser({
//       firstName: "",
//       lastName: "",
//       phoneNumber: "",
//       isAdmin: false,
//       id: "",
//     });
//     navigate("/login");
//   };

//   return (
//     <>
//       <div className="flex justify-between sticky top-0 p-2 w-[100%] z-[1] items-center h-[80px] bg-[#363740]">
//         <div className="flex flex-row items-center">
//           <div
//             className={`border-[0.5px] border-gray-500 text-gray-300 mr-2 ${
//               winWidth < 768 ? "block" : "hidden"
//             }`}
//           >
//             <MenuIcon
//               fontSize="medium"
//               onClick={() => handleSideBar()}
//             ></MenuIcon>
//           </div>
//           <div className="flex-col">
//             <h3 className=" font-bold text-white text-[12px] hidden md:block  md:text-lg lg:text-[25px] font-serif">
//               ETAMB Admin Panel
//             </h3>
//           </div>
//         </div>
//         <div className="flex w-[70%] justify-end  items-center">
//           <button
//             onClick={handleLogout}
//             className="text-[12px] sm:text-sm md:text-[18px] lg:text-[20px] md:p-1 rounded-[5px] p-0.5 bg-slate-500 text-white hover:bg-white hover:text-black mr-5"
//           >
//             Log Out
//           </button>
//         </div>
//       </div>
//       <div className="w-[100%] bg-red-500 flex flex-row">
//         <Sidebar showNavBar={showNavBar}></Sidebar>

//         <section
//           className="w-[100%] ]"
//           onClick={() => {
//             setShowNavBar("hidden");
//           }}
//         >
//           {children}
//         </section>
//       </div>
//     </>
//   );
// }

// export default Header;

import * as React from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { NavLink } from "react-router-dom";
import { Button } from "@mui/material";
import AuthContext from "../context/AuthContext";
import { removeLocalStorageItem } from "../utils/LocalStorage";
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;

function Header(props) {
  const navBar = [
    { name: "Additional Information", path: "additionalInformation" },
    { name: "Add User", path: "user" },
    { name: "Assign key to agent", path: "assignKey" },
    { name: "Approve User", path: "approveUser" },
    { name: "Bank", path: "bank" },
    { name: "Banner", path: "banner" },
    { name: "Etamb Banner", path: "etambBanner" },
    { name: "Etamb Testimony", path: "etambTestimony" },
    { name: "Constant", path: "constant" },
    { name: "Event", path: "event" },
    { name: "Field", path: "field" },
    { name: "Invited User", path: "invitedUser" },
    { name: "Message", path: "message" },
    { name: "Etamb Message", path: "etambMessage" },
    { name: "Roles", path: "role" },
    { name: "Share", path: "share" },
    { name: "Payment Request", path: "payment" },
    { name: "User Management", path: "userManagment" },
  ];
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { user, setUser } = React.useContext(AuthContext);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    removeLocalStorageItem();
    setUser({
      firstName: "",
      lastName: "",
      phoneNumber: "",
      isAdmin: false,
      id: "",
    });
    navigate("/login");
  };

  const drawer = (
    <div className="bg-[#363740] px-1.5 mt-[55px]">
      <List className="bg-[#363740] min-h-screen">
        {navBar.map((text, index) => (
          <ListItem key={index} disablePadding>
            <NavLink
              to={`/${text.path}`}
              className={({ isActive }) =>
                "nav-link text-white items-start mt-1 w-[100%] rounded-md p-1" +
                (isActive ? " bg-[#9FA2B4]" : "")
              }
            >
              <ListItemButton>
                <ListItemText primary={text.name} />
              </ListItemButton>
            </NavLink>
          </ListItem>
        ))}
        {user?.isSuperAdmin && (
          <ListItem disablePadding>
            <NavLink
              to={`/superAdmin`}
              className={({ isActive }) =>
                "nav-link text-white items-start mt-1 w-[100%] rounded-md p-1" +
                (isActive ? " bg-[#9FA2B4]" : "")
              }
            >
              <ListItemButton>
                <ListItemText primary={"Super Admin"} />
              </ListItemButton>
            </NavLink>
          </ListItem>
        )}
      </List>
      <Divider />
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div sx={{ height: "100%" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className="w-full"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar className="bg-[#363740]  flex justify-between">
          <div className="flex items-center">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>

            <Typography variant="h6" noWrap component="div">
              Admin Panel
            </Typography>
          </div>
          <Button onClick={handleLogout} variant="contained" color="warning">
            Log out
          </Button>
        </Toolbar>
      </AppBar>

      <Box sx={{ display: "flex" }}>
        <Box
          component="div"
          sx={{
            width: { sm: drawerWidth },
            flexShrink: { sm: 0 },
          }}
          aria-label="Admin Menu"
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { sm: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 2,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            height: "calc(100vh - 64px)",
          }}
        >
          <Toolbar />

          {props.children}
        </Box>
      </Box>
    </div>
  );
}

export default Header;
