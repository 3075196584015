import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import FormContainer from "../../components/CustomForm/FormContainer";
import FormField from "../../components/CustomForm/FormField";
import CustomToast from "../../components/CustomToast";
import ErrorMessage from "../../components/ErrorMessage";
import Loader from "../../utils/Loader";
import { getLocalStorageItem } from "../../utils/LocalStorage";
import {
  getRoleInformationService,
  updateRoleService,
} from "../../services/roleService";
import { ErrorHandler } from "../../utils/ErrorHandler";

const validationSchema = Yup.object().shape({
  name: Yup.string().required().label("Role"),
  scoreValue: Yup.string().required().label("Score Value"),
  fee: Yup.string().required().label("Fee"),
  allowedNumber: Yup.number().required().label("Allowed Number"),
});

const EditRole = () => {
  const { state } = useLocation();
  const [role, setRole] = useState({
    name: "",
    scoreValue: "",
    fee: "",
    allowedNumber: 0,
  });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const token = getLocalStorageItem();

  const handleSubmit = async (value) => {
    setLoading(true);
    try {
      const { data } = await updateRoleService(state?.id, value, token);
      if (data.data) {
        CustomToast("success", data.message);
        navigate("/role");
        setRole(data.data);
      }
    } catch (error) {
      console.log(
        error?.response?.data ? error?.response?.data?.message : error?.message
      );
      ErrorHandler(error).map((e) => {
        CustomToast("error", e.message);
      });
      // CustomToast(
      //   "error",
      //   error?.response?.data ? error?.response?.data?.message : error?.message
      // );
    }
    setLoading(false);
  };

  useEffect(() => {
    getRole();
  }, [state.id]);

  const getRole = async () => {
    setLoading(true);
    try {
      const { data } = await getRoleInformationService(state.id, token);
      if (data?.data) setRole(data.data);
    } catch (error) {
      console.log();
      CustomToast(
        "error",
        error?.response?.data ? error?.response?.data.message : error?.message
      );
    }
    setLoading(false);
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : role?.name ? (
        <FormContainer
          buttonTitle={"Edit Role"}
          initialValues={role}
          handleSubmit={handleSubmit}
          validationSchema={validationSchema}
          width={"w-[90%] sm:w-[80%] md:w-[60%] lg:[50%]"}
        >
          <FormField
            type={"text"}
            name={"name"}
            label={"Role Name"}
            width={"w-full"}
          ></FormField>
          <FormField
            type={"text"}
            name={"scoreValue"}
            label={"Score Value"}
            width={"w-full"}
          ></FormField>
          <FormField
            type={"text"}
            name={"fee"}
            label={"Fee to join"}
            width={"w-full"}
          ></FormField>
          <FormField
            type={"number"}
            name={"allowedNumber"}
            label={"Allowed Number"}
            width={"w-full"}
          ></FormField>
        </FormContainer>
      ) : (
        <ErrorMessage error={"Error occured, Please try again"}></ErrorMessage>
      )}
    </div>
  );
};

export default EditRole;
