import React from "react";
import { NavLink } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";

const EventPage = () => {
  const option = [
    { name: "Add Event", icon: AddIcon, to: "/event/addEvent" },
    { name: "Watch Event", icon: VisibilityIcon, to: "/event/watchEvent" },
  ];

  return (
    <div className="w-full">
      <div className="flex justify-around flex-wrap p-5">
        {option.map((options, index) => (
          <NavLink
            key={index}
            to={options.to}
            className="bg-slate-100 my-2 w-[40%] sm:w-[40%] md:w-[35%] lg:w-[20%]  p-5 rounded-lg hover:bg-slate-300 flex flex-col justify-center items-center"
          >
            {(() => {
              return (
                <options.icon
                  fontSize="sm:text-[18px] md:text-[50px]"
                  color="inherit"
                  style={{ color: "grey" }}
                />
              );
            })()}
            <p className="text-[grey] font-semibold text-lg">{options.name}</p>
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default EventPage;
