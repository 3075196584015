import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import CustomBox from "../../utils/CustomBox";
import Loader from "../../utils/Loader";
import CustomToast from "../../components/CustomToast";
import ErrorMessage from "../../components/ErrorMessage";

import { getShareService } from "../../services/shareService";
import { getLocalStorageItem } from "../../utils/LocalStorage";
import { ErrorHandler } from "../../utils/ErrorHandler";

const SharePage = () => {
  const [share, setShare] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getShare();
  }, []);

  const token = getLocalStorageItem();
  const getShare = async () => {
    setLoading(true);
    try {
      const { data } = await getShareService(token);
      setShare(data.data);
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setLoading(false);
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="flex flex-col w-full">
          <NavLink
            to={"/share/addShare"}
            className="flex flex-col justify-center items-center rounded-md bg-slate-500 w-[30%] max-w-[200px] p-2 self-end m-2 hover:bg-slate-400"
          >
            <p className="text-white text-md font-semibold">Add Share</p>
          </NavLink>

          <div className="flex flex-col items-center">
            {share.length && !loading ? (
              <div className="flex flex-wrap pl-[10%] justify-start w-[100%] items-center ">
                {share.map((share, index) => (
                  <CustomBox
                    key={index}
                    index={index}
                    to={`/share/editShare`}
                    state={{
                      id: share.id,
                      amount: share.amount,
                      percentage: share.percentage,
                      role: share?.role,
                    }}
                    title={`${share.amount} ETB`}
                    detail={`${share.role}`}
                  />
                ))}
              </div>
            ) : (
              <ErrorMessage error={"No share found, Please add share amount"} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SharePage;
