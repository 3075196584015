import React, { useState } from "react";

import CustomModalComponent from "../../common/CustomModal/CustomModal";
import AddUser from "./UserAddEdit/AddUser";

const AddUserMenu = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="flex flex-wrap pl-[10%] justify-start w-[100%] items-center">
      <div
        onClick={handleOpen}
        className="flex flex-col w-[90%] m-3 p-1 h-[150px] md:w-[40%] lg:w-[26%] xl:w-[20%] rounded-md justify-center bg-slate-200 hover:bg-slate-100"
      >
        <p>Add User</p>
      </div>

      <CustomModalComponent open={open} handleClose={handleClose}>
        <AddUser />
      </CustomModalComponent>
    </div>
  );
};

export default AddUserMenu;
