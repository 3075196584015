import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../utils/Loader";

import {
  editSpecificConstantService,
  getSpecificConstantService,
} from "../../services/constantService";
import CustomToast from "../../components/CustomToast";
import FormContainer from "../../components/CustomForm/FormContainer";
import FormField from "../../components/CustomForm/FormField";
import Header from "../../components/header";
import { getLocalStorageItem } from "../../utils/LocalStorage";
import { ErrorHandler } from "../../utils/ErrorHandler";

const EditConstant = () => {
  const { state } = useLocation();
  const [constant, setConstant] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required().label("Constant Name"),
    value: Yup.string().required().label("Constant Value"),
  });
  const token = getLocalStorageItem();

  const handleEditConstant = async (values) => {
    setLoading(true);
    try {
      const { data } = await editSpecificConstantService(values, token);
      if (data) {
        CustomToast("success", "Constant update successfully!");
        navigate("/constant");
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setLoading(false);
  };

  const getConstant = async () => {
    setLoading(true);
    try {
      const { data } = await getSpecificConstantService(state?.id, token);
      if (data) setConstant(data);
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    getConstant();
  }, []);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <FormContainer
          buttonTitle={"Edit Constant"}
          initialValues={constant}
          handleSubmit={handleEditConstant}
          validationSchema={validationSchema}
          width={"w-[100%] sm:w-[80%] md:w-[60%] lg:[50%]"}
        >
          <FormField
            type={"text"}
            width={"w-[90%]"}
            name={"name"}
            label={"Constant Name"}
          ></FormField>
          <FormField
            type={"text"}
            width={"w-[90%]"}
            name={"value"}
            label={"Constant Value"}
          ></FormField>
        </FormContainer>
      )}
    </div>
  );
};

export default EditConstant;
