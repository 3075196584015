import React from "react";
import { ErrorMessage, Field, useFormikContext } from "formik";

const CustomDropDown = ({ label, width, name, data }) => {
  return (
    <div className={`${width}`}>
      <p className="text-[#4D5959] text-left text-[14px]">{label}</p>
      <Field
        className="w-full outline-[#99d5e9] rounded-md p-2 my-2 bg-[#EFF0F2] text-[14px]"
        as="select"
        name={name}
      >
        <option value={""}>Select {label}</option>
        {data?.map((values, index) => (
          <option key={index} value={values.id}>
            {values.name}
          </option>
        ))}
      </Field>
      <ErrorMessage
        className="text-[#e95f5f] text-left text-[14px] w-full"
        name={name}
        component="p"
      />
    </div>
  );
};

export default CustomDropDown;
