import React, { useEffect, useState } from "react";
import moment from "moment";
import { Button, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { getInvitedUserBySerialKeyService } from "../../services/adminService";
import { getLocalStorageItem } from "../../utils/LocalStorage";
import CustomToast from "../../components/CustomToast";
import ErrorMessage from "../../components/ErrorMessage";
import Loader from "../../utils/Loader";
import MUITable from "../../components/Table";
import CustomPopover from "../../common/Popover";
import CustomDialog from "../../common/Dialog";
import { Edit } from "@mui/icons-material";
import EditInvitedUser from "./EditInvitedUser";

const InvitedUserList = () => {
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  useEffect(() => {
    getInvitedUser();
  }, []);

  const handleDelete = async (id) => {
    // setCatLoading(true);
    // await categoryService
    //   .delete(id as number)
    //   .then(() => {
    //     setDeleteOpen(false);
    //     setType("success");
    //     setMessage("Category deleted!");
    //     setToastOpen(true);
    //     setCatLoading(false);
    //     fetchData();
    //   })
    //   .catch((err: Error) => {
    //     setToastOpen(true);
    //     setType("error");
    //     setMessage(err?.message);
    //     setCatLoading(false);
    //   });
  };

  const getInvitedUser = async () => {
    setLoading(true);
    try {
      const token = await getLocalStorageItem();
      const { data } = await getInvitedUserBySerialKeyService(token);
      if (data.data) {
        let temp = data.data;
        let newReferal = [];
        temp.map((item) => {
          newReferal.push({
            ...item,
            inviter: `${item.inviter?.firstName}  ${item?.inviter?.lastName}`,
            referalCode: item.inviter?.referalCode,
            registered: item.registered ? "Registered" : "Not Registered",
            date: moment(item.date).format("YYYY-MM-DD"),
          });
        });
        setUser(newReferal);
      }
    } catch (error) {
      CustomToast("err", error?.response?.data?.message ?? error?.message);
    }
    setLoading(false);
  };

  const columns = [
    {
      field: "key",
      headerName: "Key",
      width: 150,
    },
    { field: "name", headerName: "Full Name", width: 200 },
    {
      field: "date",
      headerName: "Date Invited",
      width: 150,
    },
    {
      field: "inviter",
      headerName: "Inviter",
      width: 150,
    },
    {
      field: "referalCode",
      headerName: "Referal Code",
      width: 150,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: 150,
    },
    {
      field: "registered",
      headerName: "Registered",
      width: 150,
    },

    {
      headerName: "Action",
      width: 150,
      field: "action",
      renderCell: (params) => (
        <>
          <CustomPopover>
            <Button
              startIcon={<Edit />}
              onClick={() => setEditOpen(true)}
              sx={{ marginY: 0.5 }}
              color="success"
              variant="outlined"
            >
              Edit
            </Button>
            {/* <Button
              onClick={() => setDeleteOpen(true)}
              sx={{ marginY: 0.5 }}
              variant="outlined"
              color="warning"
            >
              Delete
            </Button> */}
          </CustomPopover>
          <CustomDialog
            open={deleteOpen}
            setOpen={setDeleteOpen}
            title={`Are you sure you want to delete this category `}
          >
            <Button onClick={() => setDeleteOpen(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={() => handleDelete(params?.id)} color="error">
              Yes
            </Button>
          </CustomDialog>
          <CustomDialog open={editOpen} setOpen={setEditOpen} title={`Edit`}>
            <EditInvitedUser />
          </CustomDialog>
        </>
      ),
    },
  ];

  return (
    <div className="px-10 ">
      {/* <MUITable columns={columns} data={user} /> */}
      {loading ? (
        <Loader />
      ) : user?.length ? (
        <DataGrid
          getRowId={(row) => row.referalId}
          loading={loading}
          rows={user}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
        />
      ) : (
        <ErrorMessage error="No user found" />
      )}
    </div>
  );
};

export default InvitedUserList;
