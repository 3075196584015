import React from "react";
import { Route, Routes } from "react-router-dom";

import Header from "./header";

import AddBankPage from "../Pages/BankPage/AddBankPage";
import AddBanner from "../Pages/Banner/AddBanner";
import AddConstantPage from "../Pages/ConstantPage/AddConstantPage";
import AddEventPage from "../Pages/EventsPage/AddEventPage";
import AddFieldPage from "../Pages/FieldPages/AddFieldPage";
import AddRole from "../Pages/RolePages/AddRole";
import AddSharePage from "../Pages/SharePage/AddSharePage";
import AllBanner from "../Pages/Banner/AllBanner";
import AllFieldsPage from "../Pages/FieldPages/AllFieldsPage";
import ApproveUser from "../Pages/ApproveUserPage/approveUsers";
import BankPage from "../Pages/BankPage/BankPage";
import ConstantPage from "../Pages/ConstantPage/ConstantPage";
import EditBankPage from "../Pages/BankPage/EditBankPage";
import EditBanner from "../Pages/Banner/EditBanner";
import EditConstant from "../Pages/ConstantPage/EditConstant";
import EditEventPage from "../Pages/EventsPage/EditEventPage";
import EditField from "../Pages/FieldPages/EditField";
import EditMessage from "../Pages/MessagePage/EditMessage";
import EditRole from "../Pages/RolePages/EditRole";
import EditSharePage from "../Pages/SharePage/EditSharePage";
import EventPage from "../Pages/EventsPage/EventPage";
import FieldPage from "../Pages/FieldPages/FieldPage";
import InvitedUserList from "../Pages/InvitedUserList/InvitedUserList";
import PremiumUser from "../Pages/AddUsers/PremiumUser";
import PaymentRequest from "../Pages/PaymentRequest/PaymentRequest";
import Pay from "../Pages/PaymentRequest/Pay";
import PaymentDetail from "../Pages/UserDetailPage/PaymentDetail";
import RolePage from "../Pages/RolePages/RolePage";
import SharePage from "../Pages/SharePage/SharePage";
import Message from "../Pages/MessagePage/Message";
import UserDetail from "../Pages/UserDetailPage/UserDetail";
import UserDetailForG3 from "../Pages/UserDetailPage/UserDetailForG3";
import UserDetailForG4 from "../Pages/UserDetailPage/UserDetailForG4";
import UserDetailForShare from "../Pages/UserDetailPage/UserDetailForShare";
import UserInEachRole from "../Pages/UserManagmentPage/UserInEachRole";
import UserManagement from "../Pages/UserManagmentPage/UserManagement";
import UserRequestInEachRole from "../Pages/UserManagmentPage/UserRequestInEachRole";
import WatchAllEvent from "../Pages/EventsPage/WatchAllEvent";
import AdditionalInformation from "../Pages/AdditionalInformation";
import CurrentJob from "../Pages/AdditionalInformation/CurrentJob/CurrentJob";
import CompletedService from "../Pages/AdditionalInformation/CompletedService/CompletedService";
import Skill from "../Pages/AdditionalInformation/Skill/Skill";
import Volunteer from "../Pages/AdditionalInformation/Volunteer/Volunteer";
import RoleModel from "../Pages/AdditionalInformation/RoleModel/RoleModel";
import EmergencyContact from "../Pages/AdditionalInformation/EmergencyContact/EmergencyContact";
import PersonalInfo from "../Pages/AdditionalInformation/PersonalInfo/PersonalInfo";
import InvitedUser from "../Pages/InvitedUserList";
import JoinedByPromotion from "../Pages/InvitedUserList/JoinedByPromotion";
import SuperAdmin from "../Pages/SuperAdmin";
import AddAdmin from "../Pages/SuperAdmin/AddAdmin/AddAdmin";
import ListAdmin from "../Pages/SuperAdmin/ListAdmin/ListAdmin";
import EditAdmin from "../Pages/SuperAdmin/EditAdmin/EditAdmin";
import EditUser from "../Pages/UserManagmentPage/EditUser/EditUser";
import NotFoundPage from "../Pages/NotFound/NotFoundPage";
import AllEtambBanner from "../Pages/EtambBanner/AllEtambBanner";
import AddEtambBanner from "../Pages/EtambBanner/EtambBanner";
import EditEtambBanner from "../Pages/EtambBanner/EditEtambBanner";
import EtambMessage from "../Pages/EtambMessagePage/EtambMessage";
import EditEtambMessage from "../Pages/EtambMessagePage/EditEtambMessage";
import AddEtambTestimony from "../Pages/EtambTestimony/EtambTestimony";
import AllEtambTestimony from "../Pages/EtambTestimony/AllEtambTestimony";
import EditEtambTestimony from "../Pages/EtambTestimony/EditEtambTestimony";
import AddUser from "../Pages/AddUsers/AddEditUserMenu";
import AssignKeyToAgents from "../Pages/AssignKeyToAgents/AssignKeyToAgents";

const AdminRoutes = () => {
  return (
    <Header>
      <Routes>
        <Route
          path="/"
          element={
            <div className="min-h-[calc(100vh-100px)] flex items-center justify-center">
              <p className="font-semibold font-serif text-4xl text-[#3b6fbd]">
                Welcome To ETAMB Admin Panel
              </p>
            </div>
          }
        ></Route>
        <Route path="/userManagment" element={<UserManagement />}></Route>
        <Route path="/assignKey" element={<AssignKeyToAgents />}></Route>
        <Route
          path="/userManagment/userInEachRole/userDetail/editUser"
          element={<EditUser />}
        ></Route>
        <Route
          path="/userManagment/userInEachRole/:roleId"
          element={<UserInEachRole />}
        ></Route>
        <Route
          path="/userManagment/userInEachRole/userDetail/paymentDetail"
          element={<PaymentDetail />}
        ></Route>
        <Route
          path="/userManagment/userInEachRole/userDetail"
          element={<UserDetail />}
        ></Route>

        <Route
          path="/approveUser/userRequestRole/:roleId"
          element={<UserRequestInEachRole />}
        ></Route>
        <Route
          path="/approveUser/userRequestRole/userDetailForG2"
          element={<UserDetail />}
        ></Route>
        <Route
          path="/approveUser/userRequestRole/userDetailForG3"
          element={<UserDetailForG3 />}
        ></Route>
        <Route
          path="/approveUser/userRequestRole/userDetailForG4"
          element={<UserDetailForG4 />}
        ></Route>
        <Route
          path="/approveUser/userRequestRole/userDetailForShare"
          element={<UserDetailForShare />}
        ></Route>

        <Route path="/approveUser" element={<ApproveUser />}></Route>

        {/* ---------------------------------------------- */}
        <Route path="/field" element={<FieldPage />}></Route>
        <Route path="/field/addfield" element={<AddFieldPage />}></Route>
        <Route path="/field/getfield" element={<AllFieldsPage />}></Route>
        <Route path="/field/editField" element={<EditField />}></Route>
        {/* ---------------------------------------------- */}
        <Route path="/role" element={<RolePage />}></Route>
        <Route path="/role/addRole" element={<AddRole />}></Route>
        <Route path="/role/editRole" element={<EditRole />}></Route>
        {/* ---------------------------------------------- */}
        <Route path="/constant" element={<ConstantPage />}></Route>
        {/* ---------------------------------------------- */}
        <Route path="/superAdmin" element={<SuperAdmin />}></Route>
        <Route path="/superAdmin/addAdmin" element={<AddAdmin />}></Route>
        <Route path="/superAdmin/listAdmin" element={<ListAdmin />}></Route>
        <Route path="/superAdmin/editAdmin" element={<EditAdmin />}></Route>
        {/* ---------------------------------------------- */}
        <Route
          path="/constant/addConstant"
          element={<AddConstantPage />}
        ></Route>
        <Route path="/constant/editConstant" element={<EditConstant />}></Route>
        {/* ---------------------------------------------- */}
        <Route path="/share" element={<SharePage />}></Route>
        <Route path="/share/addShare" element={<AddSharePage />}></Route>
        <Route path="/share/editShare" element={<EditSharePage />}></Route>
        {/* ---------------------------------------------- */}
        <Route path="/bank" element={<BankPage />}></Route>
        <Route path="/bank/addBank" element={<AddBankPage />}></Route>
        <Route path="/bank/editBank" element={<EditBankPage />}></Route>
        {/* ---------------------------------------------- */}
        <Route path="/event" element={<EventPage />}></Route>
        <Route path="/event/addEvent" element={<AddEventPage />}></Route>
        <Route path="/event/watchEvent" element={<WatchAllEvent />}></Route>
        <Route
          path="/event/editEvent/:eventId"
          element={<EditEventPage />}
        ></Route>

        {/* ---------------------------------------------- */}
        <Route path="/user" element={<AddUser />}></Route>
        {/* ---------------------------------------------- */}
        <Route path="/banner" element={<AllBanner />}></Route>
        <Route path="/banner/addBanner" element={<AddBanner />}></Route>
        <Route path="/banner/editBanner" element={<EditBanner />}></Route>
        {/* ---------------------------------------------- */}
        <Route path="/etambBanner" element={<AllEtambBanner />}></Route>
        <Route
          path="/etambBanner/addBanner"
          element={<AddEtambBanner />}
        ></Route>
        <Route
          path="/etambBanner/editBanner"
          element={<EditEtambBanner />}
        ></Route>
        <Route path="/etambTestimony" element={<AllEtambTestimony />}></Route>
        <Route
          path="/etambTestimony/addTestimony"
          element={<AddEtambTestimony />}
        ></Route>
        <Route
          path="/etambTestimony/editTestimony"
          element={<EditEtambTestimony />}
        ></Route>
        {/* ---------------------------------------------- */}
        <Route path="/message" element={<Message />}></Route>
        <Route path="/message/editMessage" element={<EditMessage />}></Route>
        {/* ---------------------------------------------- */}
        {/* ---------------------------------------------- */}
        <Route path="/etambMessage" element={<EtambMessage />}></Route>
        <Route
          path="/etambMessage/editMessage"
          element={<EditEtambMessage />}
        ></Route>
        {/* ---------------------------------------------- */}

        <Route path="/payment" element={<PaymentRequest />}></Route>
        <Route path="/pay" element={<Pay />}></Route>
        <Route path="/invitedUser" element={<InvitedUser />}></Route>
        <Route
          path="/invitedUser/invitedUserList"
          element={<InvitedUserList />}
        ></Route>
        <Route
          path="/invitedUser/joinedByPromotion"
          element={<JoinedByPromotion />}
        ></Route>
        {/* ---------------------------------------------- */}
        <Route
          path="/additionalInformation"
          element={<AdditionalInformation />}
        ></Route>
        <Route
          path="/additionalInformation/currentJob"
          element={<CurrentJob />}
        ></Route>
        <Route
          path="/additionalInformation/completed"
          element={<CompletedService />}
        ></Route>
        <Route path="/additionalInformation/skills" element={<Skill />}></Route>
        <Route
          path="/additionalInformation/volunteer"
          element={<Volunteer />}
        ></Route>
        <Route
          path="/additionalInformation/roleModel"
          element={<RoleModel />}
        ></Route>
        <Route
          path="/additionalInformation/emergency"
          element={<EmergencyContact />}
        ></Route>
        <Route
          path="/additionalInformation/personalInformation"
          element={<PersonalInfo />}
        ></Route>

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Header>
  );
};

export default AdminRoutes;
