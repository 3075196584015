import React, { useEffect, useState } from "react";
import { getEmergencyService } from "../../../services/AdditionalService";
import CustomToast from "../../../components/CustomToast";
import { ErrorHandler } from "../../../utils/ErrorHandler";
import MUITable from "../../../components/Table";
import { getLocalStorageItem } from "../../../utils/LocalStorage";
import Loader from "../../../utils/Loader";

const EmergencyContact = () => {
  const [emergency, setEmergency] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = getLocalStorageItem();

  const getEmergencyContact = async () => {
    setLoading(true);
    try {
      const { data } = await getEmergencyService(token);
      if (data) {
        let temp = [...data];
        temp.map((items) => {
          items.firstName = items?.user?.firstName;
          items.lastName = items?.user?.lastName;
          items.userId = items.user_id;
        });
        setEmergency(temp);
      }

      setLoading(false);
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
      setLoading(false);
    }
  };

  useEffect(() => {
    getEmergencyContact();
  }, []);
  const columns = [
    {
      label: "Full Name",
      value: "fullName",
    },
    { label: "Phone Number", value: "mobile" },
    { label: "Email", value: "email" },
    { label: "First Name", value: "firstName" },
    { label: "Last Name", value: "lastName" },
  ];

  return (
    <div className="px-10 w-full">
      {loading ? (
        <Loader />
      ) : (
        <MUITable
          path={"/userManagment/userInEachRole/userDetail"}
          type={"user"}
          columns={columns}
          data={emergency}
        />
      )}
    </div>
  );
};

export default EmergencyContact;
