import React, { useEffect, useState } from "react";

import { getLocalStorageItem } from "../../utils/LocalStorage";
import CustomToast from "../../components/CustomToast";
import Loader from "../../utils/Loader";
import { BASE_URL } from "../../config/config";
import { Link } from "react-router-dom";
import { Button, Modal, Popover } from "@mui/material";
import {
  deleteEtambBannerService,
  getEtambBannerService,
} from "../../services/etambBannerService";
import { ErrorHandler } from "../../utils/ErrorHandler";

const AllEtambBanner = () => {
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [id, setId] = useState(null);
  const token = getLocalStorageItem();

  const handleClick = (event, id) => {
    setId(id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getBanner = async () => {
    setLoading(true);
    try {
      const { data } = await getEtambBannerService(token);
      if (data) setBanner(data);
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setLoading(false);
  };

  const deleteBanner = async () => {
    setLoading(true);
    try {
      const { data } = await deleteEtambBannerService(id, token);
      if (data) {
        CustomToast("success", "Etamb Banner deleted");
        getBanner();
        handleClose();
      }
    } catch (error) {
      handleClose();
      CustomToast("err", error?.response?.data?.message ?? error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    getBanner();
  }, []);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="p-10">
          <div className="flex flex-col w-full">
            <Link
              to={"/etambBanner/addBanner"}
              className="flex flex-col justify-center items-center rounded-md bg-slate-500 w-[30%] max-w-[200px] p-2 self-end m-2 hover:bg-slate-400"
            >
              <p className="text-white text-[14px] md:text-[16px] font-semibold">
                Add Banner
              </p>
            </Link>
            {banner.map((banners, index) => (
              <div
                key={index}
                className="flex my-2 flex-col w-full bg-slate-100 rounded-lg "
              >
                <div className="flex w-[100%] flex-row">
                  <img
                    src={BASE_URL + banners.image}
                    width={500}
                    style={{
                      objectFit: "contain",
                    }}
                  ></img>
                  <p className="mx-10 text-grey">{banners.description}</p>
                </div>
                <div className="flex my-5">
                  <Link
                    key={index}
                    to={"/etambBanner/editBanner"}
                    state={{ id: banners.id }}
                  >
                    <p className="bg-blue-900 px-2 p-1 mr-1 w-[60px] text-[14px] md:text-[16px] md:w-[100px] text-white rounded-md">
                      Edit
                    </p>
                  </Link>

                  <button
                    type="button"
                    onClick={(e) => handleClick(e, banners.id)}
                    className="bg-red-900  px-2 p-1 ml-1 w-[60px] text-[14px] md:text-[16px] md:w-[100px] text-white rounded-md"
                  >
                    Delete
                  </button>
                  <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <div className="px-5 p-2.5">
                      <p>Are you sure you want to delete this banner?</p>
                      <div className="flex justify-evenly my-2">
                        <Button
                          color="success"
                          size="small"
                          variant="contained"
                          onClick={deleteBanner}
                        >
                          Yes
                        </Button>
                        <Button
                          size="small"
                          variant="contained"
                          color="error"
                          onClick={handleClose}
                        >
                          No
                        </Button>
                      </div>
                    </div>
                  </Popover>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AllEtambBanner;
