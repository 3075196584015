import React, { useState, useEffect } from "react";

import { getApproveUserRequest } from "../../services/userService";
import CustomToast from "../../components/CustomToast";
import Loader from "../../utils/Loader";
import CustomBox from "../../utils/CustomBox";
import { getLocalStorageItem } from "../../utils/LocalStorage";
import ErrorMessage from "../../components/ErrorMessage";

function ApproveUser() {
  const [role, setRole] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllUserRequest();
  }, []);

  const getAllUserRequest = async () => {
    setLoading(true);
    try {
      const token = getLocalStorageItem();
      const { data } = await getApproveUserRequest(token);
      if (data) setRole(data);
    } catch (error) {
      CustomToast("failed", error?.response?.data?.message ?? error?.message);
      if (error?.response?.data) console.log(error.response.data.message);
      else console.log("err", error.message);
    }
    setLoading(false);
  };
  return (
    <div className="flex flex-col">
      {loading ? (
        <Loader />
      ) : role.length ? (
        <div className="flex flex-col items-center">
          <div className="flex flex-wrap pl-[10%] justify-start w-[100%] items-center">
            {role?.map((roles, index) => (
              <CustomBox
                key={index}
                to={`/approveUser/userRequestRole/${
                  roles.roleId < 9 ? roles.roleId : "premiumUser"
                }`}
                state={
                  roles.roleId < 4
                    ? {
                        path: `/approveUser/userRequestRole/userDetailForG${
                          roles.roleId + 1
                        }`,
                      }
                    : {
                        path: `/approveUser/userRequestRole/userDetailForShare`, //path to page where user detail for role 5,6,7 implemented
                      }
                }
                title={`Request approval for ${roles.role}`}
                detail={roles.length}
              />
            ))}
          </div>
        </div>
      ) : (
        <ErrorMessage error={"Error occurred, Please reload the page"} />
      )}
    </div>
  );
}

export default ApproveUser;
