import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import CustomToast from "../../components/CustomToast";
import Loader from "../../utils/Loader";

import {
  deleteBankService,
  editBankService,
  getSpecificBankInfoService,
} from "../../services/bankService";
import FormContainer from "../../components/CustomForm/FormContainer";
import FormField from "../../components/CustomForm/FormField";
import { ErrorHandler } from "../../utils/ErrorHandler";
import { Button } from "@mui/material";

const validationSchema = Yup.object().shape({
  name: Yup.string().required().label("Bank Name"),
  accountNumber: Yup.string().required().label("Account Number"),
});
const EditBankPage = () => {
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const { id } = state;
  const navigate = useNavigate();

  const [bank, setBank] = useState({});
  useEffect(() => {
    getBankInfo();
    return () => {
      setBank({});
    };
  }, []);

  const getBankInfo = async () => {
    setLoading(true);
    try {
      const { data } = await getSpecificBankInfoService(id);
      if (data.data) {
        setBank(data.data);
      }
    } catch (error) {
      // console.log(error);
    }
    setLoading(false);
  };

  const handleEdit = async (value) => {
    try {
      setLoading(true);
      const { data } = await editBankService(id, value);
      if (data.data) {
        setBank(data.data);
        CustomToast("success", data?.message);
        navigate("/bank");
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setLoading(false);
  };

  const deleteBank = async () => {
    try {
      setLoading(true);
      const { data } = await deleteBankService(id);
      if (data.data) {
        CustomToast("success", data?.data);
        navigate("/bank");
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setLoading(false);
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="flex flex-col w-full">
          <Button
            onClick={deleteBank}
            type="button"
            variant="contained"
            color="error"
            sx={{ width: "150px", alignSelf: "flex-end", marginRight: 10 }}
          >
            Delete Bank
          </Button>
          <FormContainer
            buttonTitle={"Edit Bank"}
            initialValues={bank}
            handleSubmit={handleEdit}
            validationSchema={validationSchema}
            width={"w-[90%] sm:w-[80%] md:w-[60%] lg:[50%]"}
          >
            <FormField
              type={"text"}
              name={"name"}
              label={"Bank Name"}
              width={"w-full"}
            ></FormField>
            <FormField
              type={"text"}
              name={"accountNumber"}
              label={"Account Number"}
              width={"w-full"}
            ></FormField>
          </FormContainer>
        </div>
      )}
    </div>
  );
};

export default EditBankPage;
