import React from "react";
import FormDropDown from "../../../components/CustomForm/FormDropDown";
import FormDatePicker from "../../../components/CustomForm/FormDatePicker";
import FormField from "../../../components/CustomForm/FormField";
import { Chip, Divider } from "@mui/material";
import styled from "@emotion/styled";

const G3 = ({ bank }) => {
  const Root = styled("div")(({ theme }) => ({
    width: "100%",
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    "& > :not(style) ~ :not(style)": {
      marginTop: theme.spacing(2),
    },
  }));
  return (
    <>
      <Root>
        <Divider sx={{ width: "100%", my: 2 }}>
          <Chip
            label="G3 Information"
            size="small"
            sx={{ backgroundColor: "beige", color: "black" }}
          />
        </Divider>
      </Root>
      <FormDropDown
        data={bank}
        label={"Bank"}
        name={"bank"}
        width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
      />

      <FormField
        width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
        type="text"
        name="paymentReferenceNo"
        label="Payment Reference Number"
        // width={"w-[100%] md:w-[50%] px-5 my-1"}
      />

      <FormDatePicker
        label={"Payment Date"}
        name={"paymentDate"}
        className={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
      />
    </>
  );
};

export default G3;
