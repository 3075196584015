import React from "react";

const NotFoundPage = () => {
  return (
    <div>
      <h4 className="text-[red] text-[100px] font-bold">404</h4>
      <p className="text-[20px]">Page Not Found </p>
    </div>
  );
};

export default NotFoundPage;
