import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import CustomToast from "../../components/CustomToast";

import ListInformation from "../../components/ListInformation";
import {
  approveUser,
  clearShareFormService,
} from "../../services/adminService";
import { getUserPaymentInformationService } from "../../services/userService";
import Loader from "../../utils/Loader";
import { getLocalStorageItem } from "../../utils/LocalStorage";
import { Button, Popover } from "@mui/material";
import { ErrorHandler } from "../../utils/ErrorHandler";
import { getShareForRoleService } from "../../services/shareService";

const UserDetailForG5 = () => {
  const { state } = useLocation();
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [share, setShare] = useState({});
  const token = getLocalStorageItem();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const dateFormat = "dddd DD/MM/YYYY";

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getUser = async () => {
    setLoading(true);
    try {
      const { data } = await getUserPaymentInformationService(
        state?.id,
        state.role,
        token
      );
      if (data) {
        setUser(data);
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setLoading(false);
  };
  const getShareForUser = async () => {
    setLoading(true);
    try {
      const { data } = await getShareForRoleService(state.role, token);
      if (data) {
        setShare(data.data);
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setLoading(false);
  };

  const handleClearForm = async () => {
    setLoading(true);
    try {
      const { data } = await clearShareFormService(user, token);
      if (data) {
        CustomToast("success", "User input cleared");
        navigate("/approveUser");
        handleClose();
      }
    } catch (error) {
      handleClose();
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setLoading(false);
  };
  useEffect(() => {
    getUser();
    getShareForUser();
    return () => {};
  }, []);

  const handleApprove = async () => {
    setLoading(true);
    try {
      const { data } = await approveUser(
        { id: user[0].id, role: state?.role, share: share?.id },
        token
      );
      if (data) {
        CustomToast("success", "User approved");
        navigate("/approveUser");
      }
    } catch (error) {
      CustomToast("error", error?.response?.data.message || error?.message);
    }
    setLoading(false);
  };
  let users = {};
  if (!loading) users = { ...user?.at(-1) };

  const { user: userInfo, payment } = users;

  return (
    <>
      <div className="flex flex-col p-2.5 sm:p-5 md:p-10">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div>
              <p className="text-[18px] font-semibold text-left text-[grey] mt-2.5">
                Previously paid and approved
              </p>
              <div className="flex flex-wrap justify-between">
                {user
                  .filter(
                    (items, index) => items.id != user[user.length - 1].id
                  )
                  ?.map((items, index) => (
                    <div className="flex w-full md:w-[45%] my-1 flex-col bg-[#d4ffb1] p-1.5 rounded-md justify-between">
                      {items.payment?.paymentAmount && (
                        <p className="text-left text-[14px] text-[#4b4b4b] md:text-[16px]">
                          Paid Amount: {items.payment?.paymentAmount} ETB
                        </p>
                      )}
                      {items.payment?.paymentReferenceNo && (
                        <p className="text-left text-[14px] text-[#4b4b4b] md:text-[16px]">
                          Reference No: {items.payment?.paymentReferenceNo}
                        </p>
                      )}
                      {items.payment?.bank && (
                        <p className="text-left text-[14px] text-[#4b4b4b] md:text-[16px]">
                          Bank: {items.payment?.bank}
                        </p>
                      )}
                      {items.payment?.paymentDate && (
                        <p className="text-left text-[14px] text-[#4b4b4b] md:text-[16px]">
                          Paid on:{" "}
                          {moment(items.payment?.paymentDate).format(
                            dateFormat
                          )}
                        </p>
                      )}
                    </div>
                  ))}
              </div>
            </div>
            <p className="text-[18px] font-semibold text-left text-[grey] mt-2.5">
              Waiting for approval
            </p>
            <div className="flex flex-col md:flex-row flex-wrap md:justify-between">
              {userInfo?.firstName && (
                <ListInformation label="Name" value={userInfo?.firstName} />
              )}
              {userInfo?.email && (
                <ListInformation label="Email" value={userInfo?.email} />
              )}
              {userInfo?.lastName && (
                <ListInformation
                  label="Father Name"
                  value={userInfo?.lastName}
                />
              )}
              {payment?.paymentAmount && (
                <ListInformation
                  label="Share Amount"
                  value={payment?.paymentAmount}
                />
              )}
              {payment?.paymentReferenceNo && (
                <ListInformation
                  label="Payment Reference Number"
                  value={payment?.paymentReferenceNo}
                />
              )}
              {payment?.bank && (
                <ListInformation label="Bank" value={payment?.bank} />
              )}
              {payment?.paymentDate && (
                <ListInformation
                  label="Payment Date (DD/MM/YYYY)"
                  value={moment(payment?.paymentDate).format(dateFormat)}
                />
              )}
            </div>

            <div className="flex my-2 bg-slate-100 rounded-md p-5 justify-between w-[100%] md:w-[50%] max-w-[300px]">
              <button
                onClick={handleApprove}
                className="bg-green-500 w-max min-w-[80px] rounded-md cursor-pointer text-[14px] md:text-[16px] text-white p-0.5 md:p-1"
              >
                Approve
              </button>
              <button
                onClick={handleClick}
                className="bg-red-500 w-max min-w-[80px] rounded-md cursor-pointer text-[14px] md:text-[16px] text-white p-0.5 md:p-1"
              >
                Clear
              </button>
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl && anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <div className="px-5 p-2.5">
                  <p>Are you sure you want to clear the user info?</p>
                  <div className="flex justify-evenly my-2">
                    <Button
                      color="success"
                      size="small"
                      variant="contained"
                      onClick={handleClearForm}
                    >
                      Yes
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      color="error"
                      onClick={handleClose}
                    >
                      No
                    </Button>
                  </div>
                </div>
              </Popover>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default UserDetailForG5;
