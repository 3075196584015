import { Button } from "@mui/material";
import React from "react";

const FormButton = ({ title, isSubmitting }) => {
  return (
    <div className="m-2">
      <Button
        variant="contained"
        color="success"
        disabled={isSubmitting}
        type="submit"
      >
        {title}
      </Button>
    </div>
  );
};

export default FormButton;
