import axios from "axios";
import { URL } from "../config/config";
import { getToken } from "../utils/getToken";
import { queryParams } from "../utils/helper";

export const getShareService = async (query) => {
  return await axios.get(`${URL}share?${queryParams(query)}`, getToken());
};
export const getShareForRoleService = async (id) => {
  return await axios.get(`${URL}share/${id}`, getToken());
};
export const editShareService = async (id, value, token) => {
  return await axios.put(`${URL}share/${id}`, value, {
    headers: {
      "x-auth-token": token,
    },
  });
};
export const addShareService = async (value, token) => {
  return await axios.post(`${URL}share`, value, {
    headers: {
      "x-auth-token": token,
    },
  });
};
