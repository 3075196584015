import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { getLocalStorageItem } from "../../utils/LocalStorage";
import CustomToast from "../../components/CustomToast";
import FormContainer from "../../components/CustomForm/FormContainer";

import FormImage from "../../components/CustomForm/FormImage";
import FormField from "../../components/CustomForm/FormField";
import { BASE_URL } from "../../config/config";
import Loader from "../../utils/Loader";
import { ErrorHandler } from "../../utils/ErrorHandler";
import {
  editEtambTestimonyService,
  getOneEtambTestimonyService,
} from "../../services/etambTestimonyService";

const validationSchema = Yup.object().shape({
  fullName: Yup.string().required().label("Full Name is required"),
  testimony: Yup.string().required().label("Testimony is required"),
  picture: Yup.string().label("Image"),
});
const EditEtambTestimony = () => {
  const [testimony, setTestimony] = useState({});
  const [loading, setLoading] = useState(false);

  const { state } = useLocation();
  const navigation = useNavigate();
  const token = getLocalStorageItem();

  const getBanner = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getOneEtambTestimonyService(state.id, token);
      if (data) setTestimony(data);
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setLoading(false);
  }, []);

  const handleSubmit = async (values) => {
    setLoading(true);
    const formData = new FormData();
    Object.keys(values).forEach((item) => formData.append(item, values[item]));
    try {
      const { data } = await editEtambTestimonyService(formData, token);
      if (data) {
        CustomToast("success", "Etamb Banner Update");
        navigation("/etambTestimony");
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    getBanner();
  }, [getBanner]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <FormContainer
          initialValues={testimony}
          buttonTitle={"Edit Testimony"}
          handleSubmit={handleSubmit}
          width={"w-[100%] sm:w-[80%] md:w-[60%] lg:[50%] px-5"}
          validationSchema={validationSchema}
        >
          <img
            alt="img"
            src={BASE_URL + testimony?.image}
            className="w-[200px] h-[200px] object-contain rounded-[100%]"
          ></img>
          <FormField
            label={"Full Name"}
            name="fullName"
            width={"w-full"}
            type={"text"}
          />
          <FormField label={"Testimony"} name="testimony" width={"w-full"} />
          <FormImage label={"Change Image"} name="picture" width={"w-full"} />
        </FormContainer>
      )}
    </div>
  );
};

export default EditEtambTestimony;
