import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomToast from "../components/CustomToast";

import { getUserFromLocalStorage } from "./LocalStorage";

const ProtectRoute = (props) => {
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);

  const checkIfAgent = async () => {
    const user = await getUserFromLocalStorage();
    if (user && user?.isAdmin) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
      CustomToast(
        "error",
        "Access Denied! You need to login to access this page"
      );
      navigate("/login");
    }
  };
  useEffect(() => {
    checkIfAgent();
    return () => setIsAdmin(false);
  }, []);

  return <React.Fragment>{isAdmin ? props.children : null}</React.Fragment>;
};
export default ProtectRoute;
