import React, { useState, useEffect } from "react";

import Loader from "../../utils/Loader";
import { getPaymentRequestService } from "../../services/paymentService";
import { getLocalStorageItem } from "../../utils/LocalStorage";
import { ErrorHandler } from "../../utils/ErrorHandler";
import CustomToast from "../../components/CustomToast";
import MUITable from "../../components/Table";

const PaymentRequest = () => {
  const [loading, setLoading] = useState(false);
  const [request, setRequest] = useState([]);

  useEffect(() => {
    getPaymentRequest();
    return () => {
      setRequest([]);
    };
  }, []);
  const token = getLocalStorageItem();
  const getPaymentRequest = async () => {
    setLoading(true);
    try {
      const { data } = await getPaymentRequestService(token);
      if (data.data) {
        let temp = [...data.data];
        temp.map((items) => {
          items.firstName = items?.user?.firstName;
          items.lastName = items?.user?.lastName;
          items.status = items?.isPaid ? "Paid" : "Not Paid";
        });
        setRequest(temp);
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setLoading(false);
  };

  const columns = [
    { label: "First Name", value: "firstName" },
    { label: "Last Name", value: "lastName" },
    { label: "Amount", value: "amount" },
    { label: "Bank", value: "bank" },
    { label: "Account Number", value: "bankAccountNo" },
    { label: "Status", value: "status" },
  ];

  return (
    <div className="px-10 w-full">
      {loading ? (
        <Loader />
      ) : (
        <MUITable data={request} columns={columns} path={"/pay"} />
      )}
    </div>
  );
};

export default PaymentRequest;
