import React, { useEffect, useState } from "react";
import moment from "moment";
import { Button, Popover } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import CustomToast from "../../../components/CustomToast";
import { editUserService } from "../../../services/userService";
import { ErrorHandler } from "../../../utils/ErrorHandler";
import ErrorMessage from "../../../components/ErrorMessage";
import { getLocalStorageItem } from "../../../utils/LocalStorage";
import { getUserInformationByAdminService } from "../../../services/adminService";
import ListInformation from "../../../components/ListInformation";
import Loader from "../../../utils/Loader";

const EditUser = () => {
  const { state } = useLocation();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const token = getLocalStorageItem();

  const handleChange = ({ currentTarget: input }) => {
    setUser({ ...user, [input.name]: input.value });
  };
  const handleUpdate = async () => {
    setUpdateLoading(true);

    try {
      const { data } = await editUserService(user);
      if (data) {
        CustomToast("success", "User information edited!");
        navigate(`/userManagment`);
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("Err", e.message));
    }
    setUpdateLoading(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getUser = async () => {
    setLoading(true);
    try {
      const { data } = await getUserInformationByAdminService(state?.id, token);
      if (data.data) {
        const user = data.data;
        setUser({
          userId: user.userId,
          firstName: user.firstName ?? "",
          lastName: user.lastName ?? "",
          grandFatherName: user.grandFatherName ?? "",
          email: user.email ?? "",
          state: user.state ?? "",
          phoneNumber: user.phoneNumber ?? "",
          city: user.city ?? "",
          university: user.university ?? "",
          motherFullName: user.motherFullName ?? "",
          tinNumber: user.tinNumber ?? "",
          yearGraduated: user.yearGraduated ?? "",
        });
      }
    } catch (error) {
      CustomToast("error", error?.response?.data.message || error?.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    getUser();
    return () => {};
  }, []);

  return (
    <div>
      <div className="flex flex-col px-8">
        {loading || updateLoading ? (
          <Loader />
        ) : user?.firstName ? (
          <>
            <div className="flex flex-col md:flex-row flex-wrap md:justify-between">
              <ListInformation
                disabled={false}
                onChange={handleChange}
                name={"firstName"}
                label="Name"
                value={user?.firstName}
              />

              <ListInformation
                disabled={false}
                onChange={handleChange}
                name={"lastName"}
                label="Father Name"
                value={user?.lastName}
              />

              <ListInformation
                disabled={false}
                onChange={handleChange}
                name={"grandFatherName"}
                label="Grand Father Name"
                value={user?.grandFatherName}
              />

              <ListInformation
                disabled={false}
                onChange={handleChange}
                name={"email"}
                label="Email"
                value={user?.email}
              />

              {/* {!!user?.country && (
                <ListInformation
                  disabled={false}
                  onChange={handleChange}
                  name={"country"}
                  label="Country"
                  value={user?.country}
                />
              )} */}

              <ListInformation
                disabled={false}
                onChange={handleChange}
                name={"phoneNumber"}
                label="Phone Number"
                value={user?.phoneNumber}
              />
              <ListInformation
                disabled={false}
                onChange={handleChange}
                name={"city"}
                label="City"
                value={user?.city}
              />
              <ListInformation
                disabled={false}
                onChange={handleChange}
                name={"state"}
                label="State"
                value={user?.state}
              />

              {/* {!!user?.fields?.length ? (
                <div className="w-[100%] md:w-[40%] md:m-2">
                  <p className="text-left text-[14px] md:text-[16px] text-slate-500">
                    Field
                  </p>

                  {user?.fields?.map((fields, index) => (
                    <p
                      key={index}
                      className="text-left bg-slate-200 rounded-md my-0.5 p-1.5 text-[14px] md:text-[16px] justify-center item-center"
                    >
                      {fields?.name ?? ""}
                    </p>
                  ))}
                </div>
              ) : null} */}

              {/* <ListInformation
                disabled={false}
                onChange={handleChange}
                name={"university"}
                label="University"
                value={user?.university}
              /> */}
              {/* 
              {!!user?.yearGraduated && (
                <ListInformation
                  type={"date"}
                  disabled={false}
                  onChange={handleChange}
                  name={"yearGraduated"}
                  label="Year graduated"
                  value={user?.yearGraduated}
                />
              )} */}
              {/* {!!user?.birthDate && (
                <ListInformation
                  disabled={false}
                  onChange={handleChange}
                  name={"birthDate"}
                  label="Birth Date"
                  value={moment(user?.birthDate).format("DD-MMM-YYYY")}
                />
              )} */}

              <ListInformation
                disabled={false}
                onChange={handleChange}
                name={"motherFullName"}
                label="Mother Full Name"
                value={user?.motherFullName}
              />

              <ListInformation
                disabled={false}
                onChange={handleChange}
                name={"tinNumber"}
                label="Tin Number"
                value={user?.tinNumber}
              />
              <ListInformation
                label="Year Graduated"
                name="yearGraduated"
                value={moment(user?.yearGraduated).format("YYYY-MM-DD")}
                onChange={handleChange}
                type={"date"}
              />
            </div>

            <div className="flex my-5 justify-center w-[100%]">
              <button
                onClick={handleClick}
                className="bg-green-500 w-max min-w-[80px] rounded-md cursor-pointer text-[14px] md:text-[16px] text-white p-0.5 md:p-1"
              >
                Edit
              </button>

              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl && anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <div className="px-5 p-2.5">
                  <p>Are you sure you want to edit the user info?</p>
                  <div className="flex justify-evenly my-2">
                    <Button
                      color="success"
                      size="small"
                      variant="contained"
                      onClick={handleUpdate}
                    >
                      Yes
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      color="error"
                      onClick={handleClose}
                    >
                      No
                    </Button>
                  </div>
                </div>
              </Popover>
            </div>
          </>
        ) : (
          <ErrorMessage error={"No data"} />
        )}
      </div>
    </div>
  );
};

export default EditUser;
