import React from "react";
import FormField from "../../../components/CustomForm/FormField";
import FormCountrySelector from "../../../components/CustomForm/FormCountrySelector";
import CustomDropDown from "../../../components/CustomForm/CustomDropDown";
import FormDatePicker from "../../../components/CustomForm/FormDatePicker";
import FormImage from "../../../components/CustomForm/FormImage";
import { useFormikContext } from "formik";
import ErrorMessage from "../../../components/ErrorMessage";

const G2 = ({country,field,progress,progressVisible}) => {
    const {setFieldValue,values} = useFormikContext()

    const education = [
        {
          id: "postgraduate",
          name: "Postgraduate",
        },
        {
          id: "graduate",
          name: "Graduate",
        },
        {
          id: "diploma",
          name: "Diploma",
        },
        {
          id: "Secondary School",
          name: "Secondary School",
        },
        {
          id: "Primary School",
          name: "Primary School",
        },
      ];
  return (
    <>
      <FormField
        width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
        label="First Name"
        name="firstName"
        type="text"
      />
      <FormField
        width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
        label="Phone Number"
        name="phoneNumber"
        type="text"
      />
      <FormField
        width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
        label="Email"
        name="email"
        type="text"
      />
      <FormField
        width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
        label="Password"
        name="password"
        type="text"
      />

      <FormField
        width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
        label="Father Name"
        name="lastName"
        type="text"
      />
      <FormField
        width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
        label="Grand Father Name"
        name="grandFatherName"
        type="text"
      />
      <FormCountrySelector value={country} name={"country"} />
      <FormField
        width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
        label="State"
        name="state"
        type="text"
      />
      <FormField
        width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
        label="City"
        name="city"
        type="text"
      />
      <CustomDropDown
        data={education}
        label="Highest Education Level"
        name="university"
        width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
      />
      <CustomDropDown
        data={field}
        label="Field"
        name="field_id"
        width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
      />

      <FormDatePicker
        name={"yearGraduated"}
        label={"Graduation Year"}
        width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
      />

      <div className={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}>
        <FormImage
          progress={progress}
          progressVisible={progressVisible}
          label="Education certificate"
          name="document"
          width={"100%"}
        />
      </div>
      <div className={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}>
        <label
          htmlFor="field"
          className="block text-[#4D5959] text-[14px] md:text-[16px] p-[2px] "
        >
          How do you hear about us?
        </label>
        <select
          name="referal"
          onChange={(e) => {
            if (e.currentTarget.value == "others") {
              setFieldValue("referalCode", "no");
              setFieldValue("referal", e.currentTarget.value);
            } else {
              setFieldValue("referal", e.currentTarget.value);
              setFieldValue("referalCode", "");
            }
          }}
          id="field"
          className="bg-gray-50 outline-[#99d5e9] text-gray-900 rounded-lg w-full text-[14px] md:text-[16px] p-[5px]  "
        >
          <option value="">Select</option>
          <option value={"others"} className="">
            Others
          </option>
          <option value={"agent"} className="">
            Agent
          </option>
        </select>
        <ErrorMessage className="text-[red]" name="referal" component="p" />
      </div>
      {values?.referal !== "agent" && (
        <div className="w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"></div>
      )}

      {values.referal == "agent" && (
        <>
          <div className={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}>
            <FormField
              width={"w-[100%] "}
              label="Referal Code for the agent"
              name="referalCode"
              type="text"
            />
          </div>
          <div className={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}>
            <FormField
              width={"w-[100%]"}
              label="Serial Key"
              name="serialKey"
              type="number"
            />
          </div>
        </>
      )}
    </>
  );
};

export default G2;
