import React from "react";
import FormDropDown from "../../../components/CustomForm/FormDropDown";
import FormDatePicker from "../../../components/CustomForm/FormDatePicker";
import FormField from "../../../components/CustomForm/FormField";
import { Chip, Divider } from "@mui/material";
import styled from "@emotion/styled";
import FormImage from "../../../components/CustomForm/FormImage";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  "& > :not(style) ~ :not(style)": {
    marginTop: theme.spacing(2),
  },
}));

const G4 = ({ bank,role, progress, progressVisible }) => {
  return (
    <>
      <Root>
        <Divider sx={{ width: "100%", my: 2 }}>
          <Chip
            label="G4 Information"
            size="small"
            sx={{ backgroundColor: "purple", color: "white" }}
          />
        </Divider>
      </Root>
      {role < 5 ? (
        <>
          <FormDropDown
            data={bank}
            label={"Bank"}
            name={"bank"}
            width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
          />

          <FormField
            label="Payment Reference Number"
            name="paymentReferenceNo"
            type="text"
            placeholder="Payment Reference Number"
            width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
          />

          <FormDatePicker
            name="paymentDate"
            label={"Payment Date"}
            width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
          />
        </>
      ) : null}

      <FormField
        label="Mother Full Name"
        name="motherFullName"
        type="text"
        placeholder="Mother FullName"
        width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
      />

      <FormDatePicker
        name="birthDate"
        label={"Date of Birth"}
        width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
      />
      <FormField
        label="Tin Number"
        name="tinNumber"
        type="text"
        placeholder="Tin Number"
        width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
      />
      <>
        <FormImage
          label={"Profile Picture"}
          name={"profilePicture"}
          width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
          progress={progress}
          progressVisible={progressVisible}
        />
        <FormImage
          label={"Passport / National Id Picture"}
          name={"idPicture"}
          width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
          progress={progress}
          progressVisible={progressVisible}
        />
        <div className="w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"></div>
      </>
    </>
  );
};

export default G4;
