import { useEffect, useState } from "react";

import { CanceledError } from "axios";
import apiClient from "../http-service/apiClient";

const useData = (endpoint, requestConfig, deps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState();

  useEffect(() => fetchData(), deps ? [...deps] : []);

  const fetchData = () => {
    setLoading(true);
    const controller = new AbortController();
    apiClient
      .get(endpoint, {
        signal: controller.signal,
        ...requestConfig,
      })
      .then((res) => {
        if (res.data?.data) setData(res.data.data);
        else setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err instanceof CanceledError) return;
        setError(err.message);
        setLoading(false);
      });
    return () => controller.abort();
  };

  return { data, error, loading, fetchData };
};

export { useData };
