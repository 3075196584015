import React, { useEffect, useState } from "react";
import { getCurrentJobService } from "../../../services/AdditionalService";
import CustomToast from "../../../components/CustomToast";
import { ErrorHandler } from "../../../utils/ErrorHandler";
import MUITable from "../../../components/Table";
import { getLocalStorageItem } from "../../../utils/LocalStorage";
import Loader from "../../../utils/Loader";

const CurrentJob = () => {
  const [currentJob, setCurrentJob] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = getLocalStorageItem();

  const getCurrentJob = async () => {
    setLoading(true);
    try {
      const { data } = await getCurrentJobService(token);
      if (data) {
        let temp = [...data];
        temp.map((items) => (items.userId = items.user_id));
        setCurrentJob(data);
      }
      setLoading(false);
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
      setLoading(false);
    }
  };

  useEffect(() => {
    getCurrentJob();
  }, []);

  const columns = [
    {
      label: "Current Job Name Of Employer",
      value: "currentjobNameOfEmployer",
    },
    { label: "Current Job Position", value: "currentjobPosition" },
    { label: "Current Job Position", value: "currentjobCountry" },
    { label: "Current Job Position", value: "currentjobCity" },
    { label: "Current Job Position", value: "currentjobReferenceEmail" },
    { label: "Current Job Position", value: "currentjobReferenceFullName" },
    { label: "Current Job Position", value: "currentjobReferencePhoneNo" },
    { label: "Current Job Position", value: "currentjobSalary" },
    { label: "Current Job Position", value: "currentjobState" },
  ];

  return (
    <div className="px-10 w-full">
      {loading ? (
        <Loader />
      ) : (
        <MUITable
          path={"/userManagment/userInEachRole/userDetail"}
          type={"user"}
          columns={columns}
          data={currentJob}
        />
      )}
    </div>
  );
};

export default CurrentJob;
