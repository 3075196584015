import axios from "axios";
import { URL } from "../config/config";

export const getCurrentJobService = async (token) => {
  return await axios.get(`${URL}currentJob`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const getCompletedService = async (token) => {
  return await axios.get(`${URL}completedService`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const getSkillService = async (token) => {
  return await axios.get(`${URL}skill`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const getVolunteerService = async (token) => {
  return await axios.get(`${URL}volunteer`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const getRoleModelService = async (token) => {
  return await axios.get(`${URL}roleModel`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const getEmergencyService = async (token) => {
  return await axios.get(`${URL}emergencyContact`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const getPersonalInfoService = async (token) => {
  return await axios.get(`${URL}personalInformation`, {
    headers: {
      "x-auth-token": token,
    },
  });
};
