import React from "react";

const ErrorMessage = ({ error }) => {
  return (
    <p className="text-[#be6e6e] text-center mt-2 font-mono font-semibold text-[18px]">
      {error}
    </p>
  );
};

export default ErrorMessage;
