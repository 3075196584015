import jwtDecode from "jwt-decode";

const getLocalStorageItem = () => {
  try {
    return localStorage.getItem("jobPortalAdminInfo");
  } catch (error) {
    return error;
  }
};
const setLocalStorageItem = (token) => {
  try {
    return localStorage.setItem("jobPortalAdminInfo", token);
  } catch (error) {
    return error;
  }
};
const removeLocalStorageItem = () => {
  try {
    return localStorage.removeItem("jobPortalAdminInfo");
  } catch (error) {
    return error;
  }
};
const getUserFromLocalStorage = async () => {
  try {
    const token = await getLocalStorageItem();
    if (token) return await jwtDecode(token);
  } catch (error) {
    return error;
  }
};

export {
  setLocalStorageItem,
  getLocalStorageItem,
  getUserFromLocalStorage,
  removeLocalStorageItem,
};
