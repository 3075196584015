import React from "react";
import { PersonAddAlt, AdminPanelSettings } from "@mui/icons-material";
import { Link } from "react-router-dom";

const SuperAdmin = () => {
  const menus = [
    { name: "List Admins", to: "listAdmin", icon: <AdminPanelSettings /> },
    { name: "Add Admin", to: "addAdmin", icon: <PersonAddAlt /> },
  ];

  return (
    <div>
      <div className="flex flex-wrap justify-center">
        {menus.map((items, index) => (
          <Link
            key={index}
            to={`/superAdmin/${items.to}`}
            className="flex justify-evenly items-center rounded-md min-w-[150px] bg-slate-200 m-1.5  h-[80px] w-[20%]"
          >
            <p>{items.name}</p>
            {items.icon}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default SuperAdmin;
