import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import FormContainer from "../../components/CustomForm/FormContainer";
import FormDatePicker from "../../components/CustomForm/FormDatePicker";
import FormField from "../../components/CustomForm/FormField";
import FormImage from "../../components/CustomForm/FormImage";
import CustomToast from "../../components/CustomToast";
import ErrorMessage from "../../components/ErrorMessage";
import Loader from "../../utils/Loader";
import { getLocalStorageItem } from "../../utils/LocalStorage";
import { BASE_URL } from "../../config/config";
import {
  deleteEventService,
  getSpecificEventService,
  updateEventService,
} from "../../services/eventService";
import FormButton from "../../components/CustomForm/FormButton";
import { Button, Popover } from "@mui/material";

const validationSchema = Yup.object().shape({
  title: Yup.string().required().max(250).label("Event name"),
  description: Yup.string().required().max(2000).label("Event description"),
  date: Yup.date().required().label("Date of event"),
});
const EditEventPage = () => {
  const { state } = useLocation();
  const [event, setEvent] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const token = getLocalStorageItem();

  const handleSubmit = async (values) => {
    setLoading(true);

    const formData = new FormData();
    Object.keys(values).forEach((items) =>
      formData.append(items, values[items])
    );
    try {
      const { data } = await updateEventService(
        state?.eventId,
        formData,
        token
      );
      if (data.data) {
        CustomToast("success", data.message);
        setEvent(data.data);
      }
    } catch (error) {
      console.log(
        error?.response?.data ? error?.response?.data?.message : error?.message
      );
      CustomToast(
        "error",
        error?.response?.data ? error?.response?.data?.message : error?.message
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    getEvent();
  }, [state.eventId, event?.id]);

  const getEvent = async () => {
    setLoading(true);
    try {
      const { data } = await getSpecificEventService(state.eventId, token);
      if (data?.data) setEvent(data.data);
    } catch (error) {
      CustomToast(
        "error",
        error?.response?.data ? error?.response?.data.message : error?.message
      );
    }
    setLoading(false);
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : event?.title ? (
        <FormContainer
          initialValues={event}
          handleSubmit={handleSubmit}
          validationSchema={validationSchema}
          width={"w-[90%] sm:w-[80%] md:w-[60%] lg:[50%]"}
        >
          <FormField
            type={"text"}
            name={"title"}
            label={"Event"}
            width="w-full"
          ></FormField>
          <FormField
            type={"text"}
            name={"description"}
            label={"Event description"}
            as="textarea"
            cols={"5"}
            rows={"5"}
            width="w-full"
          ></FormField>
          <FormDatePicker
            name={"date"}
            title={"Event Date"}
            width="w-full"
          ></FormDatePicker>
          {event?.picture && (
            <>
              <div className="w-full">
                <p className="text-[#4D5959] text-left">Current Image</p>
                <img
                  src={`${BASE_URL}/${event?.picture}`}
                  className="w-full max-w-sm"
                ></img>
              </div>
              <FormImage
                label={"Change Image"}
                name="picture"
                width={"w-full"}
              />
            </>
          )}

          <FormButton title={"Edit Event"} />
        </FormContainer>
      ) : (
        <ErrorMessage error={"Error occurred, Please try again"}></ErrorMessage>
      )}
    </div>
  );
};

export default EditEventPage;
