import React from "react";
import CustomBox from "../../utils/CustomBox";

const AdditionalInformation = () => {
  const options = [
    { name: "Current Job", path: "currentJob" },
    { name: "Completed Service", path: "completed" },
    { name: "Skills", path: "skills" },
    { name: "Volunteer", path: "volunteer" },
    { name: "Role Model", path: "roleModel" },
    { name: "Emergency Contact", path: "emergency" },
    { name: "Personal Information", path: "personalInformation" },
  ];
  return (
    <div className="items-center w-full ">
      <div className="flex flex-wrap pl-[10%] justify-start w-[100%] items-center">
        {options.map((options, index) => (
          <CustomBox
            key={index}
            to={`/additionalInformation/${options.path}`}
            title={options.name}
          />
        ))}
      </div>
    </div>
  );
};

export default AdditionalInformation;
