import React from "react";
import { NavLink } from "react-router-dom";

const CustomBox = ({ to, state, title, detail }) => {
  return (
    <NavLink
      to={to}
      state={state}
      className="flex flex-col w-[90%] m-3 p-1 h-[150px] md:w-[40%] lg:w-[26%] xl:w-[20%] rounded-md justify-center bg-slate-200 hover:bg-slate-100"
    >
      <p className="text-slate-500 text-[14px] md:text-[16px] font-semibold">
        {title}
      </p>
      <p className="text-slate-800 text-[30px] font-bold ">{detail}</p>
    </NavLink>
  );
};

export default CustomBox;
