import axios from "axios";
import { URL } from "../config/config";

export const addBannerService = (value, token) => {
  return axios.post(`${URL}banner`, value, {
    headers: {
      "x-auth-token": token,
    },
  });
};
export const editBannerService = (value, token) => {
  return axios.put(`${URL}banner`, value, {
    headers: {
      "x-auth-token": token,
    },
  });
};
export const getBannerService = (token) => {
  return axios.get(`${URL}banner`, {
    headers: {
      "x-auth-token": token,
    },
  });
};
export const getOneBannerService = (id, token) => {
  return axios.get(`${URL}banner/${id}`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const deleteBannerService = (id, token) => {
  return axios.delete(`${URL}banner/${id}`, {
    headers: {
      "x-auth-token": token,
    },
  });
};
