import React, { useEffect, useState } from "react";
import moment from "moment";

import { getInvitedUserByPromotionService } from "../../services/adminService";
import { getLocalStorageItem } from "../../utils/LocalStorage";
import CustomToast from "../../components/CustomToast";
import ErrorMessage from "../../components/ErrorMessage";
import Loader from "../../utils/Loader";
import MUITable from "../../components/Table";

const JoinedByPromotion = () => {
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    getInvitedUser();
  }, []);

  const getInvitedUser = async () => {
    setLoading(true);
    try {
      const token = await getLocalStorageItem();
      const { data } = await getInvitedUserByPromotionService(token);
      if (data.data) {
        let temp = data.data;
        const newArray = temp.map((item) => {
          item.registrationDate = moment(item.registrationDate).format(
            "YYYY-MM-DD"
          );
          return item;
        });
        setUser(newArray);
      }
    } catch (error) {
      CustomToast("err", error?.response?.data?.message ?? error?.message);
    }
    setLoading(false);
  };

  const columns = [
    { value: "firstName", label: "First Name" },
    { value: "lastName", label: "Last Name" },
    { value: "phoneNumber", label: "Phone Number" },
    { value: "registrationDate", label: "Registered On" },
  ];
  return (
    <div className="px-10 ">
      {loading ? (
        <Loader />
      ) : user?.length ? (
        <MUITable
          path={"/userManagment/userInEachRole/userDetail"}
          columns={columns}
          data={user}
          type={"user"}
        />
      ) : (
        <ErrorMessage error="No user found" />
      )}
    </div>
  );
};

export default JoinedByPromotion;
