import React, { useEffect, useState } from "react";

import CustomToast from "../../components/CustomToast";
import Loader from "../../utils/Loader";
import { getLocalStorageItem } from "../../utils/LocalStorage";
import { getEtambMessageService } from "../../services/etambMessageService";
import MUITable from "../../components/Table";

const EtambMessage = () => {
  const [message, setMessage] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = getLocalStorageItem();
  const getMessage = async () => {
    setLoading(true);
    try {
      const { data } = await getEtambMessageService(token);
      if (data) {
        let temp = data;
        const newArray = temp.map((item) => {
          item.isContacted = item.isContacted ? "Contacted" : "Not Contacted";
          return item;
        });
        setMessage(newArray);
      }
    } catch (error) {
      CustomToast("err", error?.response?.data || error?.message);
    }
    setLoading(false);
  };
  useEffect(() => {
    getMessage();
  }, []);

  const columns = [
    { value: "fullName", label: "Full Name" },
    { value: "email", label: "Email" },
    { value: "message", label: "Message" },
    { value: "isContacted", label: "Contacted" },
  ];
  return (
    <div className="px-10 w-full">
      {loading ? (
        <Loader />
      ) : (
        <MUITable
          path="/etambMessage/editMessage"
          columns={columns}
          data={message}
        />
      )}
    </div>
  );
};

export default EtambMessage;
