import axios from "axios";
import { URL } from "../config/config";
import { getLocalStorageItem } from "../utils/LocalStorage";
import { useData } from "../hooks/useData";

export const header = () => {
  return {
    headers: {
      "x-auth-token": getLocalStorageItem(),
    },
  };
};

export const approveUser = async (value) => {
  return await axios.post(`${URL}approveUser`, value, header());
};
export const getUserInformationByAdminService = (id) => {
  return axios.get(`${URL}getUserInfo/${id}`, header());
};
export const getUserCount = () => {
  return axios.get(`${URL}getUserCountInRole`, header());
};
export const getMessageService = () => {
  return axios.get(`${URL}message`, header());
};
export const getMessageByIdService = (id) => {
  return axios.get(`${URL}message/${id}`, header());
};
export const updateMessageByIdService = (id) => {
  return axios.put(`${URL}message/${id}`, null, header());
};

export const getInvitedUserBySerialKeyService = () => {
  return axios.get(`${URL}referalUser`, header());
};
export const getInvitedUserByPromotionService = () => {
  return axios.get(`${URL}promotionUser`, header());
};
export const updateUserScoreValue = (id, scoreValue) => {
  return axios.post(`${URL}updateScoreValue/${id}`, { scoreValue }, header());
};
export const clearShareFormService = (value) => {
  return axios.post(`${URL}userShare/clearShare`, value, header());
};
export const clearFormService = (value) => {
  return axios.post(`${URL}clearForm`, value, header());
};
export const loginService = (value) => {
  return axios.post(`${URL}auth`, value);
};

export const AddAdminService = (value) => {
  return axios.post(`${URL}admin`, value, header());
};
export const getAdminService = () => {
  return axios.get(`${URL}admin`, header());
};
export const getAdminByIdService = (id) => {
  return axios.get(`${URL}admin/${id}`, header());
};
export const editAdminService = (value) => {
  return axios.put(`${URL}admin`, value, header());
};

export const submitEmailService = (email) => {
  return axios.post(`${URL}adminForgotPassword`, email, header());
};
export const resetPasswordService = (password, token) => {
  return axios.post(`${URL}adminResetPassword`, password, {
    headers: {
      "x-auth-token": token,
    },
  });
};


export const addG2Service = (value, onUploadProgress) => {
  return axios.post(`${URL}/admin/addG2`, value, {
    ...header(),
    onUploadProgress: (progress) =>
      onUploadProgress(progress.loaded / progress.total),
  });
};
export const assignAgentKeyService = () => {
  return axios.post(`${URL}user/assignKeyToAgent`,null, header());
};



export const useAgentKey = (endpoint,deps) => useData(`${endpoint}`, header(), deps);