import axios from "axios";
import { URL } from "../config/config";

export const addEtambBannerService = (value, token) => {
  return axios.post(`${URL}etambBanner`, value, {
    headers: {
      "x-auth-token": token,
    },
  });
};
export const editEtambBannerService = (value, token) => {
  return axios.put(`${URL}etambBanner`, value, {
    headers: {
      "x-auth-token": token,
    },
  });
};
export const getEtambBannerService = (token) => {
  return axios.get(`${URL}etambBanner`, {
    headers: {
      "x-auth-token": token,
    },
  });
};
export const getOneEtambBannerService = (id, token) => {
  return axios.get(`${URL}etambBanner/${id}`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const deleteEtambBannerService = (id, token) => {
  return axios.delete(`${URL}etambBanner/${id}`, {
    headers: {
      "x-auth-token": token,
    },
  });
};
