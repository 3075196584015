import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import CustomToast from "../../components/CustomToast";
import ErrorMessage from "../../components/ErrorMessage";
import Header from "../../components/header";
import CustomTable from "../../utils/CustomTable";
import Loader from "../../utils/Loader";
import { getLocalStorageItem } from "../../utils/LocalStorage";
import { getConstantService } from "../../services/constantService";
import MUITable from "../../components/Table";
import { ErrorHandler } from "../../utils/ErrorHandler";

const ConstantPage = () => {
  const [constant, setConstant] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = getLocalStorageItem();

  const getConstant = async () => {
    setLoading(true);
    try {
      const { data } = await getConstantService(token);
      if (data) setConstant(data);
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setLoading(false);
  };
  useEffect(() => {
    getConstant();
  }, []);

  const columns = [
    { label: "Constant Name", value: "name" },
    { label: "Value", value: "value" },
  ];
  return (
    <div className="w-full">
      {loading ? (
        <Loader />
      ) : (
        <div className="flex flex-col w-full px-10">
          <Link
            to={"/constant/addConstant"}
            className="flex flex-col justify-center items-center rounded-md bg-slate-500 w-[30%] max-w-[200px] p-2 self-end m-2 hover:bg-slate-400"
          >
            <p className="text-white text-md font-semibold">Add Constant</p>
          </Link>
          {constant?.length ? (
            <MUITable
              columns={columns}
              data={constant}
              path={"/constant/editConstant"}
            />
          ) : (
            // <CustomTable
            //   column={[
            //     {
            //       name: "Constant Name",
            //       value: "value",
            //     },
            //   ]}
            //   path={"/constant/editConstant"}
            //   field={filteredConstant}
            //   count={filteredConstant.length}
            //   filter={filter}
            //   setFilter={setFilter}
            // />
            <ErrorMessage error={"No constant Found"} />
          )}
        </div>
      )}
    </div>
  );
};

export default ConstantPage;
