import React from "react";
import { Field, ErrorMessage } from "formik";

function FormField({ type, name, label, as, width, disabled, row }) {
  return (
    <div className={`${width} `}>
      <p className="text-[#4D5959] text-left text-[14px]">{label}</p>
      <Field
        disabled={disabled}
        placeholder={label}
        className="w-full outline-[#99d5e9] text-[14px] rounded-md p-2 my-2 bg-[#EFF0F2]"
        type={type}
        name={name}
        as={as}
        rows={row}
      ></Field>
      <ErrorMessage
        className="text-[#e95f5f] text-left text-[14px]"
        name={name}
        component="p"
      />
    </div>
  );
}

export default FormField;
