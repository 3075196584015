import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import FormContainer from "../../components/CustomForm/FormContainer";
import FormDropDown from "../../components/CustomForm/FormDropDown";
import FormField from "../../components/CustomForm/FormField";
import CustomToast from "../../components/CustomToast";
import Loader from "../../utils/Loader";
import { getLocalStorageItem } from "../../utils/LocalStorage";
import { addShareService } from "../../services/shareService";

const validationSchema = Yup.object().shape({
  amount: Yup.number().required().label("Amount"),
  percentage: Yup.string().required().label("Percentage"),
  role: Yup.string().required().label("Role"),
});
const AddSharePage = () => {
  const share = {
    amount: "",
    percentage: "",
    role: "",
  };
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const token = getLocalStorageItem();

  const handleSubmit = async (value) => {
    setLoading(true);
    try {
      const { data } = await addShareService(value, token);
      if (data.data) {
        CustomToast("success", data.data);
        navigate("/share");
      }
    } catch (error) {
      CustomToast(
        "error",
        error?.response?.data.message || error?.response?.message
      );
    }
    setLoading(false);
  };
  return (
    <div>
      {loading && <Loader />}
      <FormContainer
        buttonTitle={"Add Share"}
        initialValues={share}
        handleSubmit={handleSubmit}
        validationSchema={validationSchema}
        width={"w-[100%] sm:w-[80%] md:w-[60%] lg:[50%]"}
      >
        <FormField
          type={"number"}
          name={"amount"}
          label={"Amount"}
          width={"w-full"}
        ></FormField>
        <FormField
          type={"text"}
          name={"percentage"}
          label={"Percentage"}
          width={"w-full"}
        ></FormField>

        <FormDropDown
          data={[
            { id: "G5", name: "G5" },
            { id: "G6", name: "G6" },
            { id: "G7", name: "G7" },
          ]}
          label="Role"
          name={"role"}
          width="w-full"
        />
      </FormContainer>
    </div>
  );
};

export default AddSharePage;
