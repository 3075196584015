import axios from "axios";
import { URL } from "../config/config";

export const addEtambTestimonyService = (value, token) => {
  return axios.post(`${URL}etambTestimony`, value, {
    headers: {
      "x-auth-token": token,
    },
  });
};
export const editEtambTestimonyService = (value, token) => {
  return axios.put(`${URL}etambTestimony`, value, {
    headers: {
      "x-auth-token": token,
    },
  });
};
export const getEtambTestimonyService = () => {
  return axios.get(`${URL}etambTestimony`);
};
export const getOneEtambTestimonyService = (id, token) => {
  return axios.get(`${URL}etambTestimony/${id}`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const deleteEtambTestimonyService = (id, token) => {
  return axios.delete(`${URL}etambTestimony/${id}`, {
    headers: {
      "x-auth-token": token,
    },
  });
};
