import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import FormContainer from "../../components/CustomForm/FormContainer";
import FormField from "../../components/CustomForm/FormField";
import CustomToast from "../../components/CustomToast";
import Loader from "../../utils/Loader";
import { getLocalStorageItem } from "../../utils/LocalStorage";
import { addFieldService } from "../../services/fieldService";

function AddFieldPage() {
  const field = {
    name: "",
    isAllowed: true,
  };
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required().label("Amount"),
  });
  const token = getLocalStorageItem();
  const handleSubmit = async (value) => {
    setLoading(true);
    try {
      const { data } = await addFieldService(value, token);
      if (data.data) {
        CustomToast("success", data.data);
        navigate("/field");
      }
    } catch (error) {
      console.log(error?.response?.data || error?.response?.message);
      CustomToast(
        "error",
        error?.response?.data.message || error?.response?.message
      );
    }
    setLoading(false);
  };

  return (
    <div>
      {loading && <Loader />}
      <FormContainer
        buttonTitle={"Add Field"}
        initialValues={field}
        handleSubmit={handleSubmit}
        validationSchema={validationSchema}
        width={"w-[90%] sm:w-[80%] md:w-[60%] lg:[50%]"}
      >
        <FormField
          type={"text"}
          name={"name"}
          label={"Field"}
          width={"w-full"}
        ></FormField>
      </FormContainer>
    </div>
  );
}

export default AddFieldPage;
