import React, { useEffect, useState } from "react";
import {
  getCurrentJobService,
  getPersonalInfoService,
  getSkillService,
} from "../../../services/AdditionalService";
import CustomToast from "../../../components/CustomToast";
import { ErrorHandler } from "../../../utils/ErrorHandler";
import MUITable from "../../../components/Table";
import { getLocalStorageItem } from "../../../utils/LocalStorage";
import Loader from "../../../utils/Loader";

const PersonalInfo = () => {
  const [info, setInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = getLocalStorageItem();

  const getInfo = async () => {
    setLoading(true);
    try {
      const { data } = await getPersonalInfoService(token);
      if (data) {
        let temp = [...data];
        temp.map((items) => {
          items.firstName = items?.user?.firstName;
          items.lastName = items?.user?.lastName;
          items.userId = items.user_id;
          let kids = "";
          items?.kidsName.map((kid) => {
            kids += kid + ",";
          });
          items.kidsName = kids;
        });
        setInfo(temp);
      }

      setLoading(false);
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
      setLoading(false);
    }
  };

  useEffect(() => {
    getInfo();
  }, []);
  const columns = [
    {
      label: "Gender",
      value: "gender",
    },
    {
      label: "Age",
      value: "age",
    },
    {
      label: "Blood Group",
      value: "bloodGroup",
    },
    {
      label: "Religion",
      value: "religion",
    },
    {
      label: "Hobby",
      value: "entertainment",
    },
    {
      label: "Allergies",
      value: "allergies",
    },
    {
      label: "Medical Case",
      value: "medicalCase",
    },
    {
      label: "Disability",
      value: "disability",
    },
    {
      label: "Martial Status",
      value: "martialStatus",
    },
    {
      label: "Kids Name",
      value: "kidsName",
    },
    { label: "First Name", value: "firstName" },
    { label: "Last Name", value: "lastName" },
  ];

  return (
    <div className="px-10 w-full">
      {loading ? (
        <Loader />
      ) : (
        <MUITable
          path={"/userManagment/userInEachRole/userDetail"}
          type={"user"}
          columns={columns}
          data={info}
        />
      )}
    </div>
  );
};

export default PersonalInfo;
