import React, { useEffect, useState } from "react";
import { getAdminService } from "../../../services/adminService";

import MUITable from "../../../components/Table";
import Loader from "../../../utils/Loader";

const ListAdmin = () => {
  const [admins, setAdmin] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getAllAdmin();
  }, []);

  const getAllAdmin = async () => {
    setLoading(true);
    try {
      const { data } = await getAdminService();
      if (data) setAdmin(data);
    } catch (error) {
      //   console.log(error.response.data.message || error.message);
    }
    setLoading(false);
  };

  const columns = [
    { label: "First Name", value: "firstName" },
    { label: "Last Name", value: "lastName" },
    { label: "Email", value: "email" },
    { label: "Phone Number", value: "phoneNumber" },
  ];

  return (
    <div className="px-10">
      {loading ? (
        <Loader />
      ) : (
        <MUITable
          path="/superAdmin/editAdmin"
          columns={columns}
          data={admins}
        />
      )}
    </div>
  );
};

export default ListAdmin;
