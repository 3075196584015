import React, { useState, useEffect, useCallback } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import jwtDecode from "jwt-decode";

import Login from "./Pages/login";
import AuthContext from "./context/AuthContext";

import AdminRoutes from "./components/AdminRoutes";
import {
  getLocalStorageItem,
  getUserFromLocalStorage,
  removeLocalStorageItem,
} from "./utils/LocalStorage";
import CustomToast from "./components/CustomToast";
import Loader from "./utils/Loader";
import ProtectRoute from "./utils/ProtectRoute";
import { refreshToken } from "./utils/RefreshToken";
import ResetPassword from "./Pages/ResetPassword";

function App() {
  const [userToken, setUserToken] = useState(getLocalStorageItem());
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const checkTokenInterval = 15 * 60 * 1000; // 5 minutes

  const checkTokenExpiration = useCallback(() => {
    let userToken = getLocalStorageItem();
    if (userToken) {
      const decodedToken = jwtDecode(userToken);
      if (decodedToken.exp * 1000 < Date.now()) {
        removeLocalStorageItem();
        setUserToken(null);
        setUser(null);
        navigate("/login");
        // CustomToast("warn", "Session has expired please login again!");
        return false;
      } else {
        return true;
      }
    } else {
      setUser(null);
      navigate("/login");
      return false;
    }
  }, [userToken]);

  const getUserProfile = useCallback(async () => {
    setLoading(true);
    try {
      if (checkTokenExpiration()) {
        const user = await getUserFromLocalStorage();
        setUser(user);
        refreshToken();
      }
    } catch (error) {
      CustomToast(
        "error",
        error.response ? error.response.data : error.message
      );
    }
    setLoading(false);
  }, [checkTokenExpiration, userToken]);

  useEffect(() => {
    getUserProfile();

    const tokenCheckInterval = setInterval(() => {
      getUserProfile();
    }, checkTokenInterval);

    return () => {
      clearInterval(tokenCheckInterval);
    };
  }, [getUserProfile]);

  return (
    <div className="App">
      <AuthContext.Provider value={{ user, setUser }}>
        {loading ? (
          <Loader />
        ) : (
          <Routes>
            <Route
              path="/*"
              element={
                <ProtectRoute>
                  <AdminRoutes />
                </ProtectRoute>
              }
            ></Route>

            <Route path="/login" element={<Login />}></Route>
            <Route
              path="/resetPassword/:token"
              element={<ResetPassword />}
            ></Route>
          </Routes>
        )}
      </AuthContext.Provider>
    </div>
  );
}

export default App;
