import React, { useEffect, useState } from "react";
import FormField from "../../../components/CustomForm/FormField";
import FormContainer from "../../../components/CustomForm/FormContainer";
import * as Yup from "yup";

import { useLocation, useNavigate } from "react-router-dom";
import {
  editAdminService,
  getAdminByIdService,
  submitEmailService,
} from "../../../services/adminService";
import CustomToast from "../../../components/CustomToast";
import Loader from "../../../utils/Loader";
import { ErrorHandler } from "../../../utils/ErrorHandler";
import { Button, Popover } from "@mui/material";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required().label("First name"),
  lastName: Yup.string().required().label("Last Name"),
  email: Yup.string().email().required().label("Email"),
  phoneNumber: Yup.string().required().label("Phone Number"),
});
const EditAdmin = () => {
  const { state } = useLocation();
  const [adminData, setAdmin] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    values.isAllowed = true;
    try {
      const { data } = await editAdminService(values);
      if (data) {
        navigate("/superAdmin/listAdmin");
        CustomToast("success", "Admin Information updated!");
      }
    } catch (error) {
      ErrorHandler(error).map((e) => {
        CustomToast("error", e.message);
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getAdminDetail();
  }, []);

  const getAdminDetail = async () => {
    setLoading(true);
    try {
      const { data } = await getAdminByIdService(state.id);
      if (data) setAdmin(data);
    } catch (error) {
      CustomToast(
        "error",
        error?.response?.data ? error?.response?.data.message : error?.message
      );
    }
    setLoading(false);
  };

  const handleResetPassword = async () => {
    setLoading(true);
    try {
      const { data } = await submitEmailService({ email: adminData?.email });
      if (data.data) {
        handleClose();
        CustomToast("success", data.data);
      }
    } catch (error) {
      handleClose();
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="flex flex-col w-full px-5">
          <Button
            type="button"
            variant="contained"
            color="error"
            onClick={handleClick}
            sx={{ width: "100%", maxWidth: "200px", alignSelf: "flex-end" }}
          >
            Reset Password
          </Button>

          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "top",
            }}
          >
            <div className="px-5 p-2.5">
              <p>Are you sure you want to reset password?</p>
              <div className="flex justify-evenly my-2">
                <Button
                  color="success"
                  size="small"
                  variant="contained"
                  onClick={handleResetPassword}
                >
                  Yes
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  color="error"
                  onClick={handleClose}
                >
                  No
                </Button>
              </div>
            </div>
          </Popover>
          <FormContainer
            buttonTitle={"Edit Admin"}
            initialValues={adminData}
            handleSubmit={handleSubmit}
            validationSchema={validationSchema}
            width={"w-[90%] sm:w-[80%] md:w-[40%] lg:[30%]"}
          >
            <FormField
              type={"text"}
              name={"firstName"}
              label={"First Name"}
              width={"w-full"}
            ></FormField>
            <FormField
              type={"text"}
              name={"lastName"}
              label={"Last Name"}
              width={"w-full"}
            ></FormField>
            <FormField
              type={"text"}
              name={"email"}
              label={"Email"}
              width={"w-full"}
            ></FormField>
            <FormField
              type={"text"}
              name={"phoneNumber"}
              label={"Phone Number"}
              width={"w-full"}
            ></FormField>
          </FormContainer>
        </div>
      )}
    </>
  );
};

export default EditAdmin;
