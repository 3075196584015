import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import CustomToast from "../../components/CustomToast";
import Loader from "../../utils/Loader";
import { getAllRoleService } from "../../services/roleService";

import ErrorMessage from "../../components/ErrorMessage";
import CustomTable from "../../utils/CustomTable";
import { getLocalStorageItem } from "../../utils/LocalStorage";
import MUITable from "../../components/Table";

const RolePage = () => {
  const [role, setRole] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = getLocalStorageItem();

  const getRole = async () => {
    setLoading(true);
    try {
      const { data } = await getAllRoleService(token);
      if (data.data) setRole(data.data);
    } catch (error) {
      CustomToast(
        "error",
        error?.response?.data ? error?.response?.data?.message : error.message
      );
    }
    setLoading(false);
  };
  useEffect(() => {
    getRole();
  }, []);

  const columns = [
    { label: "Role Name", value: "name" },
    { label: "Score Value", value: "scoreValue" },
    { label: "Fee to Join", value: "fee" },
    { label: "Allowed Number", value: "allowedNumber" },
  ];

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="flex flex-col w-full px-10">
          <NavLink
            to={"/role/addRole"}
            className="flex flex-col justify-center items-center rounded-md bg-slate-500 w-[30%] max-w-[200px] p-2 self-end m-2 hover:bg-slate-400"
          >
            <p className="text-white text-md font-semibold">Add Role</p>
          </NavLink>
          <MUITable columns={columns} data={role} path={"/role/editRole"} />
        </div>
      )}
    </div>
  );
};

export default RolePage;
