import React from "react";
import { assignAgentKeyService, useAgentKey } from "../../services/adminService";
import { Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ErrorMessage from "../../components/ErrorMessage";
import { Edit } from "@mui/icons-material";
import Loader from "../../utils/Loader";
import { useState } from "react";
import { ErrorHandler } from "../../utils/ErrorHandler";
import CustomToast from "../../components/CustomToast";



const AssignKeyToAgents = () => {
  const { data, error, fetchData, loading } = useAgentKey(`/user?agentKey=null`);
  const [agentLoading, setAgentLoading] = useState(false);

  let newUser = [];
  data?.forEach((users) => {
    newUser.push({
      ...users,
      fullName: `${users?.firstName} ${users.lastName}`,
      role: users?.role.name,
      referalCode: users?.referalCode ? users.referalCode : "Not assigned",
      agentKey: users?.agentKey ? users.agentKey : "Not assigned",
    });
  });

  const assignAgentKey = async () => {
    setAgentLoading(true);
    try {
      const { data } = await assignAgentKeyService();
      if (data) {
        CustomToast("success", "Key is assigned for agent");
        fetchData();
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setAgentLoading(false);
  };

  const columns = [
    { field: "fullName", headerName: "Full Name", width: 200 },
    {
      field: "referalCode",
      headerName: "Referal Code",
      width: 150,
    },
    {
      field: "agentKey",
      headerName: "Key",
      width: 150,
    },

    {
      field: "email",
      headerName: "Email",
      width: 150,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: 150,
    },
    {
      field: "role",
      headerName: "Role",
      width: 150,
    },

    // {
    //   headerName: "Action",
    //   width: 150,
    //   field: "action",
    //   renderCell: (params) => (
    //     <>
    //       <CustomPopover>
    //         <Button
    //           startIcon={<Edit />}
    //           // onClick={() => setEditOpen(true)}
    //           sx={{ marginY: 0.5 }}
    //           color="success"
    //           variant="outlined"
    //         >
    //           Edit
    //         </Button>
    //       </CustomPopover>
    //     </>
    //   ),
    // },
  ];

  return (
    <div className="px-10 ">
      <div className="flex justify-end my-5">
        <Button
          startIcon={<Edit />}
          onClick={() => assignAgentKey()}
          sx={{ marginY: 0.5 }}
          color="success"
          variant="outlined"
        >
          Generate Key
        </Button>
      </div>
      {agentLoading && <Loader />}
      {/* <MUITable columns={columns} data={user} /> */}
      {loading ? (
        <Loader />
      ) : (
        <DataGrid
            getRowId={(row) => row.userId}
            loading={loading}
            rows={newUser}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            autoHeight={true}
            pageSizeOptions={[5, 10]}
            />
        )}
    </div>
  );
};

export default AssignKeyToAgents;
