import React, { useState } from "react";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import FormContainer from "../../components/CustomForm/FormContainer";
import FormDatePicker from "../../components/CustomForm/FormDatePicker";
import FormField from "../../components/CustomForm/FormField";
import FormImage from "../../components/CustomForm/FormImage";
import CustomToast from "../../components/CustomToast";
import Loader from "../../utils/Loader";
import { getLocalStorageItem } from "../../utils/LocalStorage";
import { addEventService } from "../../services/eventService";

const validationSchema = Yup.object().shape({
  title: Yup.string().required().max(250).label("Event name"),
  description: Yup.string().required().max(2000).label("Event description"),
  date: Yup.date().required().label("Date of event"),
  picture: Yup.mixed()
    .test(
      "FILE_TYPE",
      "Invalid file type, Only images and pdf is allowed",
      (value) =>
        !value ||
        (value && ["image/png", "image/jpg", "image/jpeg"].includes(value.type))
    )
    .test(
      "FILE_SIZE",
      "File size is too large, please upload file less than 2 Mega Bytes",
      (value) => !value || (value && value.size <= 2000000)
    )
    .label("Picture"),
});
const AddEventPage = () => {
  const event = {
    title: "",
    description: "",
    date: "",
    picture: "",
  };
  const [loading, setLoading] = useState(false);
  const token = getLocalStorageItem();
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    setLoading(true);
    const formData = new FormData();
    Object.keys(values).forEach((item) => formData.append(item, values[item]));
    try {
      const { data } = await addEventService(formData, token);
      if (data.data) {
        CustomToast("success", data.data);
        navigate("/event");
        // window.location = "/event";
      }
    } catch (error) {
      CustomToast(
        "error",
        error?.response?.data ? error?.response?.data?.message : error?.message
      );
    }
    setLoading(false);
  };
  return (
    <div className="px-10 sm:px-0">
      {loading && <Loader />}
      <FormContainer
        buttonTitle={"Add Event"}
        initialValues={event}
        handleSubmit={handleSubmit}
        validationSchema={validationSchema}
        width={"w-[100%] sm:w-[80%] md:w-[60%] lg:[50%]"}
      >
        <FormField
          type={"text"}
          name={"title"}
          label={"Event"}
          width={"w-full"}
        ></FormField>
        <FormField
          type={"text"}
          name={"description"}
          label={"Event description"}
          as="textarea"
          cols={"20"}
          rows={"20"}
          width={"w-full"}
        ></FormField>
        <FormDatePicker
          name={"date"}
          title={"Event Date"}
          width={"w-full"}
        ></FormDatePicker>
        <FormImage label={"Picture"} name="picture" width={"w-full"} />
      </FormContainer>
    </div>
  );
};

export default AddEventPage;
