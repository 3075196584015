import axios from "axios";
import { URL } from "../config/config";
import { getToken } from "../utils/getToken";



export const getUserService = (queries) => {
  const query = queries ? Object.keys(queries).map(items=>`${items}=${queries[items]}`).join("&"):""
  return axios.get(`${URL}user?${query}`, getToken());
};

export const getApproveUserRequest = () => {
  return axios.get(`${URL}allRequest`, getToken());
};
export const getUserRequestInEachRole = (roleId, token) => {
  return axios.get(`${URL}getUserRequestInRole/${roleId}`, getToken());
};
export const getUserInEachRole = (roleId, token) => {
  return axios.get(`${URL}getUserInRole/${roleId}`, getToken());
};
export const getUserInformationService = (token) => {
  return axios.get(`${URL}getUser`, getToken());
};
export const getUserPaymentInformationService = (userId, roleId, token) => {
  return axios.post(
    `${URL}getUserPaymentInformation/${userId}`,
    { roleId },
    getToken()
  );
};
export const registerPremiumUserService = (value, token, onUploadProgress) => {
  return axios.post(`${URL}registerPremiumUser`, value, {
    ...getToken(),
    onUploadProgress: (progress) =>
      onUploadProgress(progress.loaded / progress.total),
  });
};
export const editUserService = (value) => {
  return axios.put(`${URL}editUser`, value, getToken());
};
