import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import * as Yup from "yup";

import AuthContext from "../context/AuthContext";
import FormContainer from "../components/CustomForm/FormContainer";
import FormField from "../components/CustomForm/FormField";
import { loginService } from "../services/adminService";
import { setLocalStorageItem } from "../utils/LocalStorage";
import CustomToast from "../components/CustomToast";
import Loader from "../utils/Loader";

const validationSchema = Yup.object().shape({
  email: Yup.string().required().label("Email"),
  password: Yup.string().required().label("Password"),
});
function Login() {
  const [loading, setLoading] = useState(false);
  const { setUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const { data } = await loginService(values);
      if (data.data) {
        setLocalStorageItem(data.data);
        setUser(await jwtDecode(data.data));
        navigate("/");
      }
    } catch (error) {
      if (error?.response?.data) {
        CustomToast("failed", error?.response?.data?.message);
      } else {
        CustomToast("failed", error?.message);
      }
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-purple-300 via-white-300 to-blue-300">
      {loading && <Loader />}

      <FormContainer
        buttonTitle={"Login"}
        initialValues={{ email: "", password: "" }}
        handleSubmit={handleSubmit}
        validationSchema={validationSchema}
        width={"w-[90%] md:w-[50%] max-w-[500px] px-5"}
      >
        <p className="text-[14px] md:text-[16px] font-semibold text-[grey] lg:text-[18px] ">
          Login
        </p>
        <FormField
          label={"Email"}
          name={"email"}
          type="text"
          width={"w-full"}
        ></FormField>
        <FormField
          label={"Password"}
          type="password"
          name={"password"}
          width={"w-full"}
        ></FormField>
      </FormContainer>
    </div>
  );
}

export default Login;
