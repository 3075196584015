import React, { useCallback, useEffect, useState } from "react";

import { getLocalStorageItem } from "../../utils/LocalStorage";
import CustomToast from "../../components/CustomToast";
import Loader from "../../utils/Loader";
import { BASE_URL } from "../../config/config";
import { Link } from "react-router-dom";
import { Button, Popover } from "@mui/material";
import { ErrorHandler } from "../../utils/ErrorHandler";
import {
  deleteEtambTestimonyService,
  getEtambTestimonyService,
} from "../../services/etambTestimonyService";

const AllEtambTestimony = () => {
  const [testimony, setTestimony] = useState([]);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [id, setId] = useState(null);
  const token = getLocalStorageItem();

  const handleClick = (event, id) => {
    setId(id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getTestimony = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getEtambTestimonyService();
      if (data) setTestimony(data);
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setLoading(false);
  }, []);

  const deleteTestimony = async () => {
    setLoading(true);
    try {
      const { data } = await deleteEtambTestimonyService(id, token);
      if (data) {
        CustomToast("success", "Etamb Testimony deleted");
        getTestimony();
        handleClose();
      }
    } catch (error) {
      handleClose();
      CustomToast("err", error?.response?.data?.message ?? error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    getTestimony();
  }, [getTestimony]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="p-10">
          <div className="flex flex-col w-full">
            <Link
              to={"/etambTestimony/addTestimony"}
              className="flex flex-col justify-center items-center rounded-md bg-slate-500 w-[30%] max-w-[200px] p-2 self-end m-2 hover:bg-slate-400"
            >
              <p className="text-white text-[14px] md:text-[16px] font-semibold">
                Add Testimony
              </p>
            </Link>
            {testimony.map((testimony, index) => (
              <div
                key={index}
                className="flex my-2 flex-row items-center justify-between w-full bg-slate-100 rounded-lg p-2"
              >
                <div className="flex w-[50%]  justify-start items-start flex-col">
                  <img
                    alt="img"
                    src={BASE_URL + testimony.image}
                    width={100}
                    height={100}
                    style={{
                      objectFit: "contain",
                      borderRadius: "50%",
                    }}
                  ></img>
                  <p className="text-grey">{testimony.fullName}</p>
                  <p className="text-grey font-bold">{testimony.testimony}</p>
                </div>
                <div className="flex my-5 h-max">
                  <Link
                    key={index}
                    to={"/etambTestimony/editTestimony"}
                    state={{ id: testimony.id }}
                  >
                    <p className="bg-blue-900 px-2 p-1 mr-1 w-[60px] text-[14px] md:text-[16px] md:w-[100px] text-white rounded-md">
                      Edit
                    </p>
                  </Link>

                  <button
                    type="button"
                    onClick={(e) => handleClick(e, testimony.id)}
                    className="bg-red-900  px-2 p-1 ml-1 w-[60px] text-[14px] md:text-[16px] md:w-[100px] text-white rounded-md"
                  >
                    Delete
                  </button>
                  <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <div className="px-5 p-2.5">
                      <p>Are you sure you want to delete this testimony?</p>
                      <div className="flex justify-evenly my-2">
                        <Button
                          color="success"
                          size="small"
                          variant="contained"
                          onClick={deleteTestimony}
                        >
                          Yes
                        </Button>
                        <Button
                          size="small"
                          variant="contained"
                          color="error"
                          onClick={handleClose}
                        >
                          No
                        </Button>
                      </div>
                    </div>
                  </Popover>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AllEtambTestimony;
