import axios from "axios";
import { URL } from "../config/config";

export const getAllEventService = (token) => {
  return axios.get(`${URL}event`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const getSpecificEventService = (id, token) => {
  return axios.get(`${URL}event/${id}`, {
    headers: {
      "x-auth-token": token,
    },
  });
};
export const addEventService = (value, token) => {
  return axios.post(`${URL}event`, value, {
    headers: {
      "x-auth-token": token,
    },
  });
};
export const updateEventService = (id, value, token) => {
  return axios.put(`${URL}event/${id}`, value, {
    headers: {
      "x-auth-token": token,
    },
  });
};
export const deleteEventService = (id, token) => {
  return axios.delete(`${URL}event/${id}`, {
    headers: {
      "x-auth-token": token,
    },
  });
};
