import React, { useEffect, useState } from "react";
import { getCompletedService } from "../../../services/AdditionalService";
import CustomToast from "../../../components/CustomToast";
import { ErrorHandler } from "../../../utils/ErrorHandler";
import MUITable from "../../../components/Table";
import { getLocalStorageItem } from "../../../utils/LocalStorage";
import Loader from "../../../utils/Loader";
import moment from "moment";

const CompletedService = () => {
  const [completedJob, setCompletedJob] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = getLocalStorageItem();

  const getCompletedJob = async () => {
    setLoading(true);
    try {
      const { data } = await getCompletedService(token);
      if (data) {
        let temp = [...data];
        temp.map((items) => {
          items.agreementStartDate = moment(items?.agreementStartDate).format(
            "YYYY-MM-DD"
          );
          items.agreementCompletionDate = moment(
            items?.agreementCompletionDate
          ).format("YYYY-MM-DD");
          items.firstName = items?.user?.firstName;
          items.lastName = items?.user?.lastName;
          items.userId = items?.user_id;
        });
        setCompletedJob(temp);
      }
      setLoading(false);
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
      setLoading(false);
    }
  };

  useEffect(() => {
    getCompletedJob();
  }, []);

  const columns = [
    {
      label: "Customer Name",
      value: "name",
    },
    { label: "Customer Phone No", value: "phoneNo" },
    { label: "Customer Email", value: "email" },
    { label: "Customer Country", value: "country" },
    { label: "Customer City", value: "city" },
    { label: "Customer State", value: "state" },
    { label: "Service Provided", value: "serviceProvided" },
    { label: "Agreement Start Date", value: "agreementStartDate" },
    { label: "Agreement Completion Date", value: "agreementCompletionDate" },
    { label: "Service Duration", value: "serviceDuration" },
    { label: "Amount In Birr", value: "amountInBirr" },
    { label: "Extension Period", value: "extensionPeriod" },
    { label: "Total Earned Amount", value: "totalEarnedAmount" },
    { label: "First Name", value: "firstName" },
    { label: "Last Name", value: "lastName" },
  ];

  return (
    <div className="px-10 w-full">
      {loading ? (
        <Loader />
      ) : (
        <MUITable
          path={"/userManagment/userInEachRole/userDetail"}
          type={"user"}
          columns={columns}
          data={completedJob}
        />
      )}
    </div>
  );
};

export default CompletedService;
