import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import { Person2, CampaignOutlined } from "@mui/icons-material";

const InvitedUser = () => {
  return (
    <div className="flex w-full h-full  justify-evenly">
      <Box
        sx={{
          borderRadius: 5,
          backgroundColor: "info.dark",
          border: "1px red",
          "&:hover": {
            backgroundColor: "info.main",
            opacity: [0.9, 0.8, 0.7],
          },
          height: 200,
          width: "45%",
          display: "flex",
        }}
      >
        <Link
          to={"/invitedUser/invitedUserList"}
          className="flex flex-col md:flex-row items-center justify-center w-[100%]"
        >
          <Person2 sx={{ fontSize: 150, color: "white" }} />
          <Typography color={"white"}>Invited By Agents</Typography>
        </Link>
      </Box>
      <Box
        sx={{
          borderRadius: 5,
          backgroundColor: "secondary.dark",
          border: "1px",
          "&:hover": {
            backgroundColor: "secondary.main",
            opacity: [0.9, 0.8, 0.7],
          },
          height: 200,
          width: "45%",
          display: "flex",
        }}
      >
        <Link
          to={"/invitedUser/joinedByPromotion"}
          className="flex flex-col md:flex-row items-center justify-center w-[100%]"
        >
          <CampaignOutlined sx={{ fontSize: 150, color: "white" }} />
          <Typography color={"white"}>Invited By Promotion</Typography>
        </Link>
      </Box>
    </div>
  );
};

export default InvitedUser;
